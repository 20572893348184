// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as CardTheme$OrcaPaymentPage from "../CardTheme.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as CommonHooks$OrcaPaymentPage from "../Hooks/CommonHooks.bs.js";
import * as ElementType$OrcaPaymentPage from "../Types/ElementType.bs.js";
import * as PaymentType$OrcaPaymentPage from "../Types/PaymentType.bs.js";
import * as PortalState$OrcaPaymentPage from "../Hooks/PortalState.bs.js";

var keys = Recoil$OrcaPaymentPage.atom("keys", CommonHooks$OrcaPaymentPage.defaultkeys);

var configAtom = Recoil$OrcaPaymentPage.atom("defaultRecoilConfig", CardTheme$OrcaPaymentPage.defaultRecoilConfig);

var portalNodes = Recoil$OrcaPaymentPage.atom("portalNodes", PortalState$OrcaPaymentPage.defaultDict);

var elementOptions = Recoil$OrcaPaymentPage.atom("elementOptions", ElementType$OrcaPaymentPage.defaultOptions);

var optionAtom = Recoil$OrcaPaymentPage.atom("options", PaymentType$OrcaPaymentPage.defaultOptions);

var sessions = Recoil$OrcaPaymentPage.atom("sessions", /* Loading */0);

var list = Recoil$OrcaPaymentPage.atom("paymentMethodList", /* Loading */0);

var loggerAtom = Recoil$OrcaPaymentPage.atom("component", OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);

var sessionId = Recoil$OrcaPaymentPage.atom("sessionId", "");

var selectedOptionAtom = Recoil$OrcaPaymentPage.atom("selectedOption", "");

var paymentTokenAtom = Recoil$OrcaPaymentPage.atom("paymentToken", [
      "",
      ""
    ]);

var showCardFeildsAtom = Recoil$OrcaPaymentPage.atom("showCardFeilds", false);

var phoneJson = Recoil$OrcaPaymentPage.atom("phoneJson", /* Loading */0);

var cardBrand = Recoil$OrcaPaymentPage.atom("cardBrand", "");

var defaultFieldValues = {
  value: "",
  isValid: undefined,
  errorString: ""
};

var userFullName = Recoil$OrcaPaymentPage.atom("userFullName", defaultFieldValues);

var userEmailAddress = Recoil$OrcaPaymentPage.atom("userEmailAddress", defaultFieldValues);

var userPhoneNumber = Recoil$OrcaPaymentPage.atom("userPhoneNumber", {
      value: "+351 ",
      isValid: undefined,
      errorString: ""
    });

var userCountry = Recoil$OrcaPaymentPage.atom("userCountry", "");

var userBank = Recoil$OrcaPaymentPage.atom("userBank", "");

var userAddressline1 = Recoil$OrcaPaymentPage.atom("userAddressline1", defaultFieldValues);

var userAddressline2 = Recoil$OrcaPaymentPage.atom("userAddressline2", defaultFieldValues);

var userAddressCity = Recoil$OrcaPaymentPage.atom("userAddressCity", defaultFieldValues);

var userAddressPincode = Recoil$OrcaPaymentPage.atom("userAddressPincode", defaultFieldValues);

var userAddressState = Recoil$OrcaPaymentPage.atom("userAddressState", defaultFieldValues);

var userAddressCountry = Recoil$OrcaPaymentPage.atom("userAddressCountry", defaultFieldValues);

var testMode = Recoil$OrcaPaymentPage.atom("testModeForSDK", false);

var endPoint = Recoil$OrcaPaymentPage.atom("endpoint", "https://sandbox.hyperswitch.io");

var userBlikCode = Recoil$OrcaPaymentPage.atom("userBlikCode", defaultFieldValues);

var fieldsComplete = Recoil$OrcaPaymentPage.atom("fieldsComplete", false);

var isManualRetryEnabled = Recoil$OrcaPaymentPage.atom("isManualRetryEnabled", false);

var userCurrency = Recoil$OrcaPaymentPage.atom("userCurrency", "");

export {
  keys ,
  configAtom ,
  portalNodes ,
  elementOptions ,
  optionAtom ,
  sessions ,
  list ,
  loggerAtom ,
  sessionId ,
  selectedOptionAtom ,
  paymentTokenAtom ,
  showCardFeildsAtom ,
  phoneJson ,
  cardBrand ,
  defaultFieldValues ,
  userFullName ,
  userEmailAddress ,
  userPhoneNumber ,
  userCountry ,
  userBank ,
  userAddressline1 ,
  userAddressline2 ,
  userAddressCity ,
  userAddressPincode ,
  userAddressState ,
  userAddressCountry ,
  testMode ,
  endPoint ,
  userBlikCode ,
  fieldsComplete ,
  isManualRetryEnabled ,
  userCurrency ,
  
}
/* keys Not a pure module */
