// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function jsonToPaymentRequestDataType(jsonDict) {
  var getTotal = function (totalDict) {
    if (Utils$OrcaPaymentPage.getString(totalDict, "type", "") === "") {
      return {
              label: Utils$OrcaPaymentPage.getString(totalDict, "label", ""),
              amount: Utils$OrcaPaymentPage.getString(totalDict, "amount", "")
            };
    } else {
      return {
              label: Utils$OrcaPaymentPage.getString(totalDict, "label", ""),
              type: Utils$OrcaPaymentPage.getString(totalDict, "type", ""),
              amount: Utils$OrcaPaymentPage.getString(totalDict, "amount", "")
            };
    }
  };
  if (Utils$OrcaPaymentPage.getString(jsonDict, "merchant_identifier", "") === "") {
    return {
            countryCode: Utils$OrcaPaymentPage.getString(jsonDict, "country_code", ""),
            currencyCode: Utils$OrcaPaymentPage.getString(jsonDict, "currency_code", ""),
            total: getTotal(Utils$OrcaPaymentPage.getDictFromObj(jsonDict, "total")),
            merchantCapabilities: Utils$OrcaPaymentPage.getStrArray(jsonDict, "merchant_capabilities"),
            supportedNetworks: Utils$OrcaPaymentPage.getStrArray(jsonDict, "supported_networks")
          };
  } else {
    return {
            countryCode: Utils$OrcaPaymentPage.getString(jsonDict, "country_code", ""),
            currencyCode: Utils$OrcaPaymentPage.getString(jsonDict, "currency_code", ""),
            total: getTotal(Utils$OrcaPaymentPage.getDictFromObj(jsonDict, "total")),
            merchantCapabilities: Utils$OrcaPaymentPage.getStrArray(jsonDict, "merchant_capabilities"),
            supportedNetworks: Utils$OrcaPaymentPage.getStrArray(jsonDict, "supported_networks"),
            merchantIdentifier: Utils$OrcaPaymentPage.getString(jsonDict, "merchant_identifier", "")
          };
  }
}

export {
  jsonToPaymentRequestDataType ,
  
}
/* Utils-OrcaPaymentPage Not a pure module */
