// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Recoil from "recoil";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";

function PaymentField(Props) {
  var setValue = Props.setValue;
  var value = Props.value;
  var onChange = Props.onChange;
  var onBlur = Props.onBlur;
  var rightIconOpt = Props.rightIcon;
  var fieldNameOpt = Props.fieldName;
  var nameOpt = Props.name;
  var type_Opt = Props.type_;
  var paymentType = Props.paymentType;
  var maxLength = Props.maxLength;
  var pattern = Props.pattern;
  var placeholderOpt = Props.placeholder;
  var classNameOpt = Props.className;
  var inputRef = Props.inputRef;
  var rightIcon = rightIconOpt !== undefined ? Caml_option.valFromOption(rightIconOpt) : null;
  var fieldName = fieldNameOpt !== undefined ? fieldNameOpt : "";
  var name = nameOpt !== undefined ? nameOpt : "";
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var config = match.config;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var themeObj = match$1.themeObj;
  var match$2 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.optionAtom);
  var match$3 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var parentURL = match$3.parentURL;
  var match$4 = React.useState(function () {
        return false;
      });
  var setInputFocused = match$4[1];
  var inputFocused = match$4[0];
  var handleFocus = function (param) {
    Curry._1(setInputFocused, (function (param) {
            return true;
          }));
    if (setValue !== undefined) {
      setValue(function (prev) {
            return {
                    value: prev.value,
                    isValid: undefined,
                    errorString: ""
                  };
          });
    }
    return Utils$OrcaPaymentPage.handleOnFocusPostMessage(parentURL, undefined);
  };
  var handleBlur = function (ev) {
    Curry._1(setInputFocused, (function (param) {
            return false;
          }));
    if (onBlur !== undefined) {
      Curry._1(onBlur, ev);
    }
    return Utils$OrcaPaymentPage.handleOnBlurPostMessage(parentURL, undefined);
  };
  var backgroundClass = paymentType !== 1 ? "transparent" : themeObj.colorBackground;
  var direction = type_ === "password" || type_ === "tel" ? "ltr" : "";
  var focusClass = inputFocused || value.value.length > 0 ? "mb-7 pb-1 pt-2 " + themeObj.fontSizeXs + " transition-all ease-in duration-75" : "transition-all ease-in duration-75";
  var floatinglabelClass = inputFocused ? "Label--floating" : "Label--resting";
  var getClassName = function (initialLabel) {
    if (value.value.length === 0) {
      return initialLabel + "--empty";
    }
    var valid = value.isValid;
    if (valid !== undefined && !valid) {
      return initialLabel + "--invalid";
    } else {
      return "";
    }
  };
  var labelClass = getClassName("Label");
  var inputClass = getClassName("Input");
  var tmp = {
    ref: inputRef,
    className: "Input " + inputClass + " " + className + " focus:outline-none transition-shadow ease-out duration-200",
    style: {
      background: backgroundClass,
      padding: themeObj.spacingUnit,
      width: "100%"
    },
    autoComplete: "on",
    disabled: match$2.readOnly,
    name: name,
    placeholder: config.appearance.labels === /* Above */0 ? placeholder : "",
    type: type_,
    value: value.value,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: onChange
  };
  if (maxLength !== undefined) {
    tmp.maxLength = Caml_option.valFromOption(maxLength);
  }
  if (pattern !== undefined) {
    tmp.pattern = Caml_option.valFromOption(pattern);
  }
  return React.createElement("div", {
              className: "flex flex-col w-full",
              style: {
                color: themeObj.colorText
              }
            }, React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: fieldName.length > 0 && config.appearance.labels === /* Above */0,
                  children: React.createElement("div", {
                        className: "Label " + labelClass,
                        style: {
                          fontSize: themeObj.fontSizeLg,
                          fontWeight: themeObj.fontWeightNormal,
                          marginBottom: "5px"
                        }
                      }, fieldName)
                }), React.createElement("div", {
                  className: "flex flex-row ",
                  style: {
                    direction: direction
                  }
                }, React.createElement("div", {
                      className: "relative w-full"
                    }, React.createElement("input", tmp), React.createElement(RenderIf$OrcaPaymentPage.make, {
                          condition: config.appearance.labels === /* Floating */1,
                          children: React.createElement("div", {
                                className: "Label " + floatinglabelClass + " " + labelClass + " absolute bottom-0 ml-3 " + focusClass + " text-opacity-20",
                                style: {
                                  fontSize: inputFocused || value.value.length > 0 ? themeObj.fontSizeXs : "",
                                  marginBottom: inputFocused || value.value.length > 0 ? "" : themeObj.spacingUnit
                                }
                              }, fieldName)
                        })), React.createElement("div", {
                      className: "relative flex -ml-10  items-center"
                    }, rightIcon)), React.createElement(RenderIf$OrcaPaymentPage.make, {
                  condition: value.errorString.length > 0,
                  children: React.createElement("div", {
                        className: "Error pt-1",
                        style: {
                          color: themeObj.colorDangerText,
                          fontSize: themeObj.fontSizeSm,
                          textAlign: "left",
                          alignSelf: "start"
                        }
                      }, value.errorString)
                }));
}

var make = PaymentField;

export {
  make ,
  
}
/* react Not a pure module */
