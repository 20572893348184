// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

function getLabel($$var) {
  switch ($$var) {
    case /* Default */0 :
        return "plain";
    case /* Buy */1 :
        return "buy";
    case /* Donate */2 :
        return "donate";
    case /* Checkout */3 :
        return "checkout";
    case /* Subscribe */4 :
        return "subscribe";
    case /* Book */5 :
        return "book";
    case /* Pay */6 :
        return "pay";
    case /* Order */7 :
        return "order";
    
  }
}

var defaultTokenizationData = {
  token: ""
};

var defaultPaymentMethodData_info = {};

var defaultPaymentMethodData_tokenizationData = {};

var defaultPaymentMethodData = {
  description: "",
  info: defaultPaymentMethodData_info,
  tokenizationData: defaultPaymentMethodData_tokenizationData,
  type: ""
};

function getTokenizationData(str, dict) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            token: Utils$OrcaPaymentPage.getString(json, "token", "")
                          };
                  })), defaultTokenizationData);
}

function getPaymentMethodData(str, dict) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            description: Utils$OrcaPaymentPage.getString(json, "description", ""),
                            info: Utils$OrcaPaymentPage.getJsonFromDict(json, "info", {}),
                            tokenizationData: Utils$OrcaPaymentPage.getJsonFromDict(json, "tokenizationData", {}),
                            type: Utils$OrcaPaymentPage.getString(json, "type", "")
                          };
                  })), defaultPaymentMethodData);
}

function itemToObjMapper(dict) {
  return {
          paymentMethodData: getPaymentMethodData("paymentMethodData", dict)
        };
}

function jsonToPaymentRequestDataType(paymentRequest, jsonDict) {
  paymentRequest.allowedPaymentMethods = Utils$OrcaPaymentPage.getArray(jsonDict, "allowed_payment_methods").map(function (json) {
        return Utils$OrcaPaymentPage.transformKeys(json, /* CamelCase */0);
      });
  paymentRequest.transactionInfo = Utils$OrcaPaymentPage.transformKeys(Utils$OrcaPaymentPage.getJsonFromDict(jsonDict, "transaction_info", null), /* CamelCase */0);
  paymentRequest.merchantInfo = Utils$OrcaPaymentPage.transformKeys(Utils$OrcaPaymentPage.getJsonFromDict(jsonDict, "merchant_info", null), /* CamelCase */0);
  return paymentRequest;
}

export {
  getLabel ,
  defaultTokenizationData ,
  defaultPaymentMethodData ,
  getTokenizationData ,
  getPaymentMethodData ,
  itemToObjMapper ,
  jsonToPaymentRequestDataType ,
  
}
/* Utils-OrcaPaymentPage Not a pure module */
