// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";

function getIconStyle(str, logger) {
  switch (str) {
    case "default" :
        return /* Default */0;
    case "solid" :
        return /* Solid */1;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "default",
              "solid"
            ], "options.iconStyle")(logger);
      return /* Default */0;
  }
}

var defaultClasses = {
  base: "StripeElement",
  complete: "StripeElement--complete",
  empty: "StripeElement--empty",
  focus: "StripeElement--focus",
  invalid: "StripeElement--invalid",
  webkitAutofill: "StripeElement--webkit-autofill"
};

var defaultStyleClass = {
  backgroundColor: "",
  color: "",
  fontFamily: "",
  fontSize: "",
  fontSmoothing: "",
  fontStyle: "",
  fontVariant: "",
  fontWeight: "",
  iconColor: "",
  lineHeight: "",
  letterSpacing: "",
  textAlign: "",
  padding: "",
  textDecoration: "",
  textShadow: "",
  textTransform: "",
  hover: undefined,
  focus: undefined,
  selection: undefined,
  webkitAutofill: undefined,
  disabled: undefined,
  msClear: undefined,
  placeholder: undefined
};

var defaultPaymentRequestButton = {
  type_: "default",
  theme: /* Dark */0,
  height: ""
};

var defaultStyle_base = {};

var defaultStyle_complete = {};

var defaultStyle_empty = {};

var defaultStyle_invalid = {};

var defaultStyle = {
  base: defaultStyle_base,
  complete: defaultStyle_complete,
  empty: defaultStyle_empty,
  invalid: defaultStyle_invalid,
  paymentRequestButton: defaultPaymentRequestButton
};

var defaultOptions = {
  classes: defaultClasses,
  style: defaultStyle,
  value: "",
  hidePostalCode: false,
  iconStyle: /* Default */0,
  hideIcon: false,
  showIcon: false,
  disabled: false
};

function getClasses(str, dict, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            base: Utils$OrcaPaymentPage.getWarningString(json, "base", "StripeElement", logger),
                            complete: Utils$OrcaPaymentPage.getWarningString(json, "complete", "StripeElement--complete", logger),
                            empty: Utils$OrcaPaymentPage.getWarningString(json, "empty", "StripeElement--empty", logger),
                            focus: Utils$OrcaPaymentPage.getWarningString(json, "focus", "StripeElement--focus", logger),
                            invalid: Utils$OrcaPaymentPage.getWarningString(json, "invalid", "StripeElement--invalid", logger),
                            webkitAutofill: Utils$OrcaPaymentPage.getWarningString(json, "webkitAutofill", "StripeElement--webkit-autofill", logger)
                          };
                  })), defaultClasses);
}

function getStyleObj(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            backgroundColor: Utils$OrcaPaymentPage.getWarningString(json, "backgroundColor", "", logger),
                            color: Utils$OrcaPaymentPage.getWarningString(json, "color", "", logger),
                            fontFamily: Utils$OrcaPaymentPage.getWarningString(json, "fontFamily", "", logger),
                            fontSize: Utils$OrcaPaymentPage.getWarningString(json, "fontSize", "", logger),
                            fontSmoothing: Utils$OrcaPaymentPage.getWarningString(json, "fontSmoothing", "", logger),
                            fontStyle: Utils$OrcaPaymentPage.getWarningString(json, "fontStyle", "", logger),
                            fontVariant: Utils$OrcaPaymentPage.getWarningString(json, "fontVariant", "", logger),
                            fontWeight: Utils$OrcaPaymentPage.getWarningString(json, "fontWeight", "", logger),
                            iconColor: Utils$OrcaPaymentPage.getWarningString(json, "iconColor", "", logger),
                            lineHeight: Utils$OrcaPaymentPage.getWarningString(json, "lineHeight", "", logger),
                            letterSpacing: Utils$OrcaPaymentPage.getWarningString(json, "letterSpacing", "", logger),
                            textAlign: Utils$OrcaPaymentPage.getWarningString(json, "textAlign", "", logger),
                            padding: Utils$OrcaPaymentPage.getWarningString(json, "padding", "", logger),
                            textDecoration: Utils$OrcaPaymentPage.getWarningString(json, "textDecoration", "", logger),
                            textShadow: Utils$OrcaPaymentPage.getWarningString(json, "textShadow", "", logger),
                            textTransform: Utils$OrcaPaymentPage.getWarningString(json, "textTransform", "", logger),
                            hover: getStyleObj(json, ":hover", logger),
                            focus: getStyleObj(json, ":focus", logger),
                            selection: getStyleObj(json, "::selection", logger),
                            webkitAutofill: getStyleObj(json, ":-webkit-autofill", logger),
                            disabled: getStyleObj(json, ":disabled", logger),
                            msClear: getStyleObj(json, "::-ms-clear", logger),
                            placeholder: getStyleObj(json, "::placeholder", logger)
                          };
                  })), defaultStyleClass);
}

function getTheme(str, key, logger) {
  switch (str) {
    case "dark" :
        return /* Dark */0;
    case "light" :
        return /* Light */1;
    case "light-outline" :
        return /* LightOutline */2;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "dark",
              "light",
              "light-outline"
            ], key)(logger);
      return /* Dark */0;
  }
}

function getPaymentRequestButton(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            type_: Utils$OrcaPaymentPage.getWarningString(json, "type", "", logger),
                            theme: getTheme(Utils$OrcaPaymentPage.getWarningString(json, "theme", "dark", logger), "elements.options.style.paymentRequestButton.theme", logger),
                            height: Utils$OrcaPaymentPage.getWarningString(json, "height", "", logger)
                          };
                  })), defaultPaymentRequestButton);
}

function getStyle(dict, str, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            base: Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "base"),
                            complete: Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "complete"),
                            empty: Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "empty"),
                            invalid: Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "invalid"),
                            paymentRequestButton: getPaymentRequestButton(json, "paymentRequestButton", logger)
                          };
                  })), defaultStyle);
}

function itemToObjMapper(dict, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "classes",
        "style",
        "value",
        "hidePostalCode",
        "iconStyle",
        "hideIcon",
        "showIcon",
        "disabled"
      ], dict, "options", logger);
  return {
          classes: getClasses("classes", dict, logger),
          style: getStyle(dict, "style", logger),
          value: Utils$OrcaPaymentPage.getWarningString(dict, "value", "", logger),
          hidePostalCode: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "hidePostalCode", false, logger),
          iconStyle: getIconStyle(Utils$OrcaPaymentPage.getWarningString(dict, "iconStyle", "default", logger), logger),
          hideIcon: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "hideIcon", false, logger),
          showIcon: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "showIcon", false, logger),
          disabled: Utils$OrcaPaymentPage.getBoolWithWarning(dict, "disabled", false, logger)
        };
}

export {
  getIconStyle ,
  defaultClasses ,
  defaultStyleClass ,
  defaultPaymentRequestButton ,
  defaultStyle ,
  defaultOptions ,
  getClasses ,
  getStyleObj ,
  getTheme ,
  getPaymentRequestButton ,
  getStyle ,
  itemToObjMapper ,
  
}
/* Utils-OrcaPaymentPage Not a pure module */
