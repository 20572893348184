// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hyper$OrcaPaymentPage from "./Hyper.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";

function loadHyper(str, option) {
  return Promise.resolve(function (param) {
              return Hyper$OrcaPaymentPage.make(str, option, param);
            });
}

function loadStripe(str, option) {
  ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* DEPRECATED_LOADSTRIPE */40, undefined, OrcaLogger$OrcaPaymentPage.defaultLoggerConfig, undefined);
  return Promise.resolve(function (param) {
              return Hyper$OrcaPaymentPage.make(str, option, param);
            });
}

window.Hyper = Hyper$OrcaPaymentPage.make;

var isWordpress = window.wp !== null;

if (!isWordpress) {
  window.Stripe = Hyper$OrcaPaymentPage.make;
}

export {
  loadHyper ,
  loadStripe ,
  isWordpress ,
  
}
/*  Not a pure module */
