// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getLogtype(val) {
  switch (val) {
    case "err" :
        return /* Err */4;
    case "method" :
        return /* Method */3;
    case "no_response" :
        return /* NoResponse */2;
    case "request" :
        return /* Request */0;
    case "response" :
        return /* Response */1;
    default:
      return /* Err */4;
  }
}

function logApi(eventName, statusCodeOpt, dataOpt, type_, urlOpt, paymentMethodOpt, resultOpt, optLogger, logTypeOpt, logCategoryOpt, param) {
  var statusCode = statusCodeOpt !== undefined ? statusCodeOpt : "";
  var data = dataOpt !== undefined ? Caml_option.valFromOption(dataOpt) : ({});
  var url = urlOpt !== undefined ? urlOpt : "";
  var paymentMethod = paymentMethodOpt !== undefined ? paymentMethodOpt : "";
  var result = resultOpt !== undefined ? Caml_option.valFromOption(resultOpt) : ({});
  var logType = logTypeOpt !== undefined ? logTypeOpt : /* INFO */1;
  var logCategory = logCategoryOpt !== undefined ? logCategoryOpt : /* API */0;
  var logtype = getLogtype(type_);
  var value;
  switch (logtype) {
    case /* Request */0 :
        value = [[
            "url",
            url
          ]];
        break;
    case /* NoResponse */2 :
        value = [
          [
            "url",
            url
          ],
          [
            "statusCode",
            "504"
          ],
          [
            "response",
            data
          ]
        ];
        break;
    case /* Method */3 :
        value = [
          [
            "method",
            paymentMethod
          ],
          [
            "result",
            result
          ]
        ];
        break;
    case /* Response */1 :
    case /* Err */4 :
        value = [
          [
            "url",
            url
          ],
          [
            "statusCode",
            statusCode
          ],
          [
            "response",
            data
          ]
        ];
        break;
    
  }
  if (optLogger !== undefined) {
    Curry._8(optLogger.setLogApi, {
          TAG: /* ArrayType */0,
          _0: value
        }, eventName, undefined, logType, logCategory, undefined, type_, undefined);
    return Curry._1(optLogger.sendLogs, undefined);
  }
  
}

function logInputChangeInfo(text, logger) {
  return Curry._7(logger.setLogInfo, JSON.stringify(Js_dict.fromArray([[
                        "inputChange",
                        text
                      ]])), /* INPUT_FIELD_CHANGED */10, undefined, undefined, undefined, undefined, undefined);
}

function handleLogging(optLogger, value, eventName, paymentMethod) {
  if (optLogger !== undefined) {
    return Curry._7(optLogger.setLogInfo, value, eventName, undefined, undefined, undefined, paymentMethod, undefined);
  }
  
}

export {
  getLogtype ,
  logApi ,
  logInputChangeInfo ,
  handleLogging ,
  
}
/* No side effect */
