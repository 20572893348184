// Generated by ReScript, PLEASE EDIT WITH CARE


var This = {};

function confirmPaymentFn(_elements) {
  return Promise.resolve({});
}

function confirmCardPaymentFn(_clientSecretId, _data, _options) {
  return Promise.resolve({});
}

function retrievePaymentIntentFn(_paymentIntentId) {
  return Promise.resolve({});
}

function update(_options) {
  
}

function getElement(_componentName) {
  
}

function fetchUpdates(param) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve({});
                      }), 1000);
                
              }));
}

function defaultPaymentElement_on(_str, _func) {
  
}

function defaultPaymentElement_collapse(param) {
  
}

function defaultPaymentElement_blur(param) {
  
}

function defaultPaymentElement_update(_x) {
  
}

function defaultPaymentElement_destroy(param) {
  
}

function defaultPaymentElement_unmount(param) {
  
}

function defaultPaymentElement_mount(_string) {
  
}

function defaultPaymentElement_focus(param) {
  
}

function defaultPaymentElement_clear(param) {
  
}

var defaultPaymentElement = {
  on: defaultPaymentElement_on,
  collapse: defaultPaymentElement_collapse,
  blur: defaultPaymentElement_blur,
  update: defaultPaymentElement_update,
  destroy: defaultPaymentElement_destroy,
  unmount: defaultPaymentElement_unmount,
  mount: defaultPaymentElement_mount,
  focus: defaultPaymentElement_focus,
  clear: defaultPaymentElement_clear
};

function create(_componentType, _options) {
  return defaultPaymentElement;
}

var defaultElement = {
  getElement: getElement,
  update: update,
  fetchUpdates: fetchUpdates,
  create: create
};

function defaultStripeInstance_elements(_ev) {
  return defaultElement;
}

function defaultStripeInstance_widgets(_ev) {
  return defaultElement;
}

function defaultStripeInstance_paymentRequest(_ev) {
  return null;
}

var defaultStripeInstance = {
  confirmPayment: confirmPaymentFn,
  elements: defaultStripeInstance_elements,
  confirmCardPayment: confirmCardPaymentFn,
  retrievePaymentIntent: retrievePaymentIntentFn,
  widgets: defaultStripeInstance_widgets,
  paymentRequest: defaultStripeInstance_paymentRequest
};

function eventTypeMapper($$event) {
  switch ($$event) {
    case "blur" :
        return /* Blur */5;
    case "change" :
        return /* Change */1;
    case "clickTriggered" :
        return /* Click */2;
    case "confirmTriggered" :
        return /* ConfirmPayment */6;
    case "escape" :
        return /* Escape */0;
    case "focus" :
        return /* Focus */4;
    case "ready" :
        return /* Ready */3;
    default:
      return /* None */7;
  }
}

export {
  This ,
  confirmPaymentFn ,
  confirmCardPaymentFn ,
  retrievePaymentIntentFn ,
  update ,
  getElement ,
  fetchUpdates ,
  defaultPaymentElement ,
  create ,
  defaultElement ,
  defaultStripeInstance ,
  eventTypeMapper ,
  
}
/* confirmCardPaymentFn Not a pure module */
