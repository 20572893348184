// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultToken_allowed_payment_methods = [];

var defaultToken_transaction_info = {};

var defaultToken_merchant_info = {};

var defaultToken = {
  walletName: /* NONE */4,
  token: "",
  sessionId: "",
  allowed_payment_methods: defaultToken_allowed_payment_methods,
  transaction_info: defaultToken_transaction_info,
  merchant_info: defaultToken_merchant_info
};

function getWallet(str) {
  switch (str) {
    case "apple_pay" :
        return /* ApplePay */3;
    case "google_pay" :
        return /* Gpay */0;
    case "klarna" :
        return /* Klarna */2;
    case "paypal" :
        return /* Paypal */1;
    default:
      return /* NONE */4;
  }
}

function getSessionsToken(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), (function (arr) {
                    return arr.map(function (json) {
                                var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                                return {
                                        walletName: getWallet(Utils$OrcaPaymentPage.getString(dict, "wallet_name", "")),
                                        token: Utils$OrcaPaymentPage.getString(dict, "session_token", ""),
                                        sessionId: Utils$OrcaPaymentPage.getString(dict, "session_id", ""),
                                        allowed_payment_methods: Utils$OrcaPaymentPage.getArray(dict, "allowed_payment_methods"),
                                        transaction_info: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "transaction_info"),
                                        merchant_info: Utils$OrcaPaymentPage.getJsonObjectFromDict(dict, "merchant_info")
                                      };
                              });
                  })), [defaultToken]);
}

function getSessionsTokenJson(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []);
}

function itemToObjMapper(dict, returnType) {
  switch (returnType) {
    case /* ApplePayObject */0 :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: /* ApplePayToken */0,
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case /* GooglePayThirdPartyObject */1 :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: /* GooglePayThirdPartyToken */1,
                  _0: getSessionsTokenJson(dict, "session_token")
                }
              };
    case /* Others */2 :
        return {
                paymentId: Utils$OrcaPaymentPage.getString(dict, "payment_id", ""),
                clientSecret: Utils$OrcaPaymentPage.getString(dict, "client_secret", ""),
                sessionsToken: {
                  TAG: /* OtherToken */2,
                  _0: getSessionsToken(dict, "session_token")
                }
              };
    
  }
}

function getWalletFromTokenType(arr, val) {
  return Caml_option.undefined_to_opt(arr.find(function (item) {
                  return getWallet(Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(item), (function (x) {
                                            return Js_dict.get(x, "wallet_name");
                                          })), Js_json.decodeString), "")) === val;
                }));
}

function getPaymentSessionObj(tokenType, val) {
  switch (tokenType.TAG | 0) {
    case /* ApplePayToken */0 :
        return {
                TAG: /* ApplePayTokenOptional */0,
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case /* GooglePayThirdPartyToken */1 :
        return {
                TAG: /* GooglePayThirdPartyTokenOptional */1,
                _0: getWalletFromTokenType(tokenType._0, val)
              };
    case /* OtherToken */2 :
        return {
                TAG: /* OtherTokenOptional */2,
                _0: Caml_option.undefined_to_opt(tokenType._0.find(function (item) {
                          return item.walletName === val;
                        }))
              };
    
  }
}

export {
  defaultToken ,
  getWallet ,
  getSessionsToken ,
  getSessionsTokenJson ,
  itemToObjMapper ,
  getWalletFromTokenType ,
  getPaymentSessionObj ,
  
}
/* Utils-OrcaPaymentPage Not a pure module */
