// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";

var defaultConfirm = {
  return_url: "",
  publishableKey: ""
};

function getConfirmParams(dict, str) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    return {
                            return_url: Utils$OrcaPaymentPage.getString(json, "return_url", ""),
                            publishableKey: Utils$OrcaPaymentPage.getString(json, "publishableKey", "")
                          };
                  })), defaultConfirm);
}

function itemToObjMapper(dict) {
  return {
          doSubmit: Utils$OrcaPaymentPage.getBool(dict, "doSubmit", false),
          clientSecret: Utils$OrcaPaymentPage.getString(dict, "clientSecret", ""),
          confirmParams: getConfirmParams(dict, "confirmParams")
        };
}

export {
  defaultConfirm ,
  getConfirmParams ,
  itemToObjMapper ,
  
}
/* Utils-OrcaPaymentPage Not a pure module */
