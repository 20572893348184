// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Recoil from "recoil";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Utils$OrcaPaymentPage from "./Utils.bs.js";
import * as Promise$OrcaPaymentPage from "../libraries/Promise.bs.js";
import * as OrcaUtils$OrcaPaymentPage from "../orca-loader/OrcaUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import KountWebClientSdk from "@kount/kount-web-client-sdk";
import * as ApiEndpoint$OrcaPaymentPage from "./ApiEndpoint.bs.js";
import * as BrowserSpec$OrcaPaymentPage from "../BrowserSpec.bs.js";
import * as CommonHooks$OrcaPaymentPage from "../Hooks/CommonHooks.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "./LoggerUtils.bs.js";
import * as PaymentBody$OrcaPaymentPage from "./PaymentBody.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "./RecoilAtoms.bs.js";
import * as PaymentError$OrcaPaymentPage from "../Types/PaymentError.bs.js";
import * as PaymentConfirmTypes$OrcaPaymentPage from "../Types/PaymentConfirmTypes.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function closePaymentLoaderIfAny(param) {
  return Utils$OrcaPaymentPage.handlePostMessage(undefined, [[
                "fullscreen",
                false
              ]]);
}

function retrievePaymentIntent(clientSecret, headers, optLogger) {
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var paymentIntentID = Caml_array.get(clientSecret.split("_secret_"), 0);
  var endpoint = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.endPoint);
  var uri = endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL_INIT */11, undefined, undefined, "request", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
  return Promise$OrcaPaymentPage.$$catch(fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers)), /* Get */0, undefined).then(function (res) {
                    var statusCode = String(res.status);
                    if (statusCode.charAt(0) !== "2") {
                      res.json().then(function (data) {
                            LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL */12, statusCode, Caml_option.some(data), "err", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                            return Promise.resolve(undefined);
                          });
                    } else {
                      LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL */12, statusCode, undefined, "response", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
                    }
                    return res.json();
                  }).then(function (res) {
                  return Promise.resolve(res);
                }), (function (e) {
                console.log("Unable to retrieve payment details because of ", e);
                return Promise.resolve({});
              }));
}

function pollRetrievePaymentIntent(clientSecret, headers, optLogger) {
  return Promise$OrcaPaymentPage.$$catch(retrievePaymentIntent(clientSecret, headers, optLogger).then(function (json) {
                  var dict = Belt_Option.getWithDefault(Js_json.decodeObject(json), {});
                  var status = Utils$OrcaPaymentPage.getString(dict, "status", "");
                  if (status === "succeeded" || status === "failed") {
                    return Promise.resolve(json);
                  } else {
                    return Utils$OrcaPaymentPage.delay(2000).then(function (_val) {
                                return pollRetrievePaymentIntent(clientSecret, headers, optLogger);
                              });
                  }
                }), (function (e) {
                console.log("Unable to retrieve payment due to following error", e);
                return pollRetrievePaymentIntent(clientSecret, headers, optLogger);
              }));
}

function intentCall(fetchApi, uri, headers, bodyStr, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, fetchMethod, setIsManualRetryEnabled) {
  var isConfirm = uri.includes("/confirm");
  var match = isConfirm ? [
      /* CONFIRM_CALL */14,
      /* CONFIRM_CALL_INIT */13
    ] : [
      /* RETRIEVE_CALL */12,
      /* RETRIEVE_CALL_INIT */11
    ];
  var eventName = match[0];
  LoggerUtils$OrcaPaymentPage.logApi(match[1], undefined, undefined, "request", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
  Promise$OrcaPaymentPage.$$catch(Curry._5(fetchApi, uri, bodyStr, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers)), fetchMethod, undefined).then(function (res) {
            var statusCode = String(res.status);
            var url = new URL(confirmParam.return_url);
            url.searchParams.set("payment_intent_client_secret", clientSecret);
            url.searchParams.set("status", "failed");
            if (statusCode.charAt(0) !== "2") {
              Promise$OrcaPaymentPage.$$catch(res.json().then(function (data) {
                        if (isConfirm) {
                          var paymentMethod = paymentType !== 0 ? Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(JSON.parse(bodyStr)), "payment_method_type", "") : "CARD";
                          LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, JSON.stringify(data), /* PAYMENT_FAILED */7, paymentMethod);
                        }
                        LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, Caml_option.some(data), "err", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                        var dict = Utils$OrcaPaymentPage.getDictFromJson(data);
                        var errorObj = PaymentError$OrcaPaymentPage.itemToObjMapper(dict);
                        closePaymentLoaderIfAny(undefined);
                        Utils$OrcaPaymentPage.postFailedSubmitResponse(errorObj.error.type_, errorObj.error.message);
                        if (handleUserError) {
                          Utils$OrcaPaymentPage.openUrl(url.href);
                        }
                        return Promise.resolve(undefined);
                      }), (function (err) {
                      var exceptionJson = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                      return prim.message;
                                    })), OrcaUtils$OrcaPaymentPage.safeParseOpt), null);
                      LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, Caml_option.some(exceptionJson), "no_response", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                      closePaymentLoaderIfAny(undefined);
                      Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
                      if (handleUserError) {
                        Utils$OrcaPaymentPage.openUrl(url.href);
                      }
                      return Promise.resolve(undefined);
                    }));
            } else {
              res.json().then(function (data) {
                    LoggerUtils$OrcaPaymentPage.logApi(eventName, statusCode, undefined, "response", uri, undefined, undefined, optLogger, undefined, undefined, undefined);
                    var intent = PaymentConfirmTypes$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(data));
                    var paymentMethod = paymentType !== 0 ? intent.payment_method_type : "CARD";
                    var url = new URL(confirmParam.return_url);
                    url.searchParams.set("payment_intent_client_secret", clientSecret);
                    url.searchParams.set("status", intent.status);
                    if (intent.status === "requires_customer_action") {
                      if (intent.nextAction.type_ === "redirect_to_url") {
                        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.nextAction.redirectToUrl, /* REDIRECTING_USER */52, paymentMethod);
                        Utils$OrcaPaymentPage.openUrl(intent.nextAction.redirectToUrl);
                      } else if (intent.nextAction.type_ === "display_bank_transfer_information") {
                        var obj = intent.nextAction.bank_transfer_steps_and_charges_details;
                        var metadata = obj !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(Caml_option.valFromOption(obj)) : ({});
                        var dict = Utils$OrcaPaymentPage.deepCopyDict(metadata);
                        dict["data"] = data;
                        dict["url"] = url.href;
                        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, JSON.stringify(dict), /* DISPLAY_BANK_TRANSFER_INFO_PAGE */53, paymentMethod);
                        Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                              [
                                "fullscreen",
                                true
                              ],
                              [
                                "param",
                                intent.payment_method_type + "BankTransfer"
                              ],
                              [
                                "iframeId",
                                iframeId
                              ],
                              [
                                "metadata",
                                dict
                              ]
                            ]);
                      } else if (intent.nextAction.type_ === "qr_code_information") {
                        var qrData = Belt_Option.getWithDefault(intent.nextAction.image_data_url, "");
                        var headerObj = {};
                        headers.forEach(function (entries) {
                              headerObj[entries[0]] = entries[1];
                              
                            });
                        var metaData = Js_dict.fromArray([
                              [
                                "qrData",
                                qrData
                              ],
                              [
                                "paymentIntentId",
                                clientSecret
                              ],
                              [
                                "headers",
                                headerObj
                              ],
                              [
                                "expiryTime",
                                null
                              ],
                              [
                                "url",
                                url.href
                              ]
                            ]);
                        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, JSON.stringify(metaData), /* DISPLAY_QR_CODE_INFO_PAGE */54, paymentMethod);
                        Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                              [
                                "fullscreen",
                                true
                              ],
                              [
                                "param",
                                "qrData"
                              ],
                              [
                                "iframeId",
                                iframeId
                              ],
                              [
                                "metadata",
                                metaData
                              ]
                            ]);
                      } else if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                        var token = intent.nextAction.session_token;
                        var session_token = token !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(Caml_option.valFromOption(token)) : ({});
                        var walletName = Utils$OrcaPaymentPage.getString(session_token, "wallet_name", "");
                        var message;
                        switch (walletName) {
                          case "apple_pay" :
                              message = [
                                [
                                  "applePayButtonClicked",
                                  true
                                ],
                                [
                                  "applePayPresent",
                                  session_token
                                ]
                              ];
                              break;
                          case "google_pay" :
                              message = [[
                                  "googlePayThirdPartyFlow",
                                  session_token
                                ]];
                              break;
                          default:
                            message = [];
                        }
                        Utils$OrcaPaymentPage.handlePostMessage(undefined, message);
                      } else {
                        Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                        if (uri.includes("force_sync=true")) {
                          Utils$OrcaPaymentPage.openUrl(url.href);
                        }
                        
                      }
                    } else if (intent.status === "processing") {
                      if (intent.nextAction.type_ === "third_party_sdk_session_token") {
                        var token$1 = intent.nextAction.session_token;
                        var session_token$1 = token$1 !== undefined ? Utils$OrcaPaymentPage.getDictFromJson(Caml_option.valFromOption(token$1)) : ({});
                        var walletName$1 = Utils$OrcaPaymentPage.getString(session_token$1, "wallet_name", "");
                        var message$1;
                        switch (walletName$1) {
                          case "apple_pay" :
                              message$1 = [
                                [
                                  "applePayButtonClicked",
                                  true
                                ],
                                [
                                  "applePayPresent",
                                  session_token$1
                                ]
                              ];
                              break;
                          case "google_pay" :
                              message$1 = [[
                                  "googlePayThirdPartyFlow",
                                  session_token$1
                                ]];
                              break;
                          default:
                            message$1 = [];
                        }
                        Utils$OrcaPaymentPage.handlePostMessage(undefined, message$1);
                      } else if (paymentType !== 0) {
                        Utils$OrcaPaymentPage.openUrl(url.href);
                      } else {
                        Utils$OrcaPaymentPage.postSubmitResponse(data, url.href);
                      }
                    } else if (intent.status !== "") {
                      if (intent.status === "succeeded") {
                        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, /* PAYMENT_SUCCESS */6, paymentMethod);
                      } else if (intent.status === "failed") {
                        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, intent.status, /* PAYMENT_FAILED */7, paymentMethod);
                      }
                      if (intent.status === "failed") {
                        setIsManualRetryEnabled(function (param) {
                              return intent.manualRetryAllowed;
                            });
                      }
                      if (paymentType !== 0) {
                        Utils$OrcaPaymentPage.openUrl(url.href);
                      } else {
                        Utils$OrcaPaymentPage.postSubmitResponse(data, url.href);
                      }
                    } else {
                      Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
                    }
                    return Promise.resolve(undefined);
                  });
            }
            return Promise.resolve(undefined);
          }), (function (err) {
          var url = new URL(confirmParam.return_url);
          url.searchParams.set("payment_intent_client_secret", clientSecret);
          url.searchParams.set("status", "failed");
          var exceptionJson = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                          return prim.message;
                        })), OrcaUtils$OrcaPaymentPage.safeParseOpt), null);
          LoggerUtils$OrcaPaymentPage.logApi(eventName, undefined, Caml_option.some(exceptionJson), "no_response", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
          closePaymentLoaderIfAny(undefined);
          Utils$OrcaPaymentPage.postFailedSubmitResponse("server_error", "Something went wrong");
          if (handleUserError) {
            Utils$OrcaPaymentPage.openUrl(url.href);
          }
          return Promise.resolve(undefined);
        }));
  
}

function usePaymentSync(optLogger, paymentType) {
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var list = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.list);
  var endpoint = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.endPoint);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var setIsManualRetryEnabled = Recoil.useSetRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  return function (handleUserErrorOpt, confirmParam, iframeIdOpt, param) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : "";
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("SYNC_PAYMENT_FAILED", "Sync Payment Failed. Try Again!");
    }
    var paymentIntentID = Caml_array.get(clientSecret.split("_secret_"), 0);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ]
    ];
    var uri = endpoint + "/payments/" + paymentIntentID + "?force_sync=true&client_secret=" + clientSecret;
    if (typeof list === "number" || list.TAG !== /* Loaded */0) {
      return ;
    } else {
      return intentCall(fetchApi, uri, headers, "", confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, /* Get */0, setIsManualRetryEnabled);
    }
  };
}

function maskPayload(payloadDict) {
  var keys = Object.keys(payloadDict);
  var maskedPayload = {};
  keys.map(function (key) {
        var value = Belt_Option.getWithDefault(Js_dict.get(payloadDict, key), null);
        if (Belt_Option.isSome(Js_json.decodeObject(value))) {
          var valueDict = Utils$OrcaPaymentPage.getDictFromJson(value);
          maskedPayload[key] = maskPayload(valueDict);
          return ;
        }
        maskedPayload[key] = Belt_Option.getWithDefault(Js_json.decodeString(value), "").replace(/\S/g, "x");
        
      });
  return JSON.stringify(maskedPayload);
}

function usePaymentIntent(optLogger, paymentType) {
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var endpoint = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.endPoint);
  var list = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.list);
  var keys = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var match = Recoil.useRecoilState(RecoilAtoms$OrcaPaymentPage.isManualRetryEnabled);
  var setIsManualRetryEnabled = match[1];
  var isManualRetryEnabled = match[0];
  return function (handleUserErrorOpt, bodyArr, confirmParam, iframeIdOpt, param) {
    var handleUserError = handleUserErrorOpt !== undefined ? handleUserErrorOpt : false;
    var iframeId = iframeIdOpt !== undefined ? iframeIdOpt : "";
    var clientSecret = keys.clientSecret;
    if (clientSecret === undefined) {
      return Utils$OrcaPaymentPage.postFailedSubmitResponse("confirm_payment_failed", "Payment failed. Try again!");
    }
    var paymentIntentID = Caml_array.get(clientSecret.split("_secret_"), 0);
    var headers = [
      [
        "Content-Type",
        "application/json"
      ],
      [
        "api-key",
        confirmParam.publishableKey
      ]
    ];
    var returnUrlArr = [[
        "return_url",
        confirmParam.return_url
      ]];
    var manual_retry = isManualRetryEnabled ? [[
          "retry_action",
          "manual_retry"
        ]] : [];
    var body = [[
          "client_secret",
          clientSecret
        ]].concat(returnUrlArr, manual_retry);
    var uri = endpoint + "/payments/" + paymentIntentID + "/confirm";
    var callIntent = function (body) {
      var maskedPayload = maskPayload(Utils$OrcaPaymentPage.getDictFromJson(Belt_Option.getWithDefault(OrcaUtils$OrcaPaymentPage.safeParseOpt(body), null)));
      var loggerPayload = JSON.stringify(Js_dict.fromArray([
                [
                  "payload",
                  maskedPayload
                ],
                [
                  "headers",
                  Js_dict.fromArray(headers.map(function (header) {
                            return [
                                    header[0],
                                    header[1]
                                  ];
                          }))
                ]
              ]));
      if (paymentType === /* Card */0) {
        LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, loggerPayload, /* PAYMENT_ATTEMPT */5, "CARD");
      } else {
        bodyArr.map(function (param) {
              if (param[0] === "payment_method_type") {
                LoggerUtils$OrcaPaymentPage.handleLogging(optLogger, loggerPayload, /* PAYMENT_ATTEMPT */5, Belt_Option.getWithDefault(Js_json.decodeString(param[1]), ""));
              }
              
            });
      }
      return intentCall(fetchApi, uri, headers, body, confirmParam, clientSecret, optLogger, handleUserError, paymentType, iframeId, /* Post */2, setIsManualRetryEnabled);
    };
    var intentWithoutMandate = function (param) {
      return callIntent(JSON.stringify(Js_dict.fromArray(body.concat(bodyArr.concat(BrowserSpec$OrcaPaymentPage.broswerInfo(undefined))))));
    };
    if (typeof list === "number") {
      if (list === /* Loading */0) {
        return ;
      } else {
        return intentWithoutMandate(undefined);
      }
    }
    if (list.TAG !== /* Loaded */0) {
      return ;
    }
    var paymentList = PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(list._0));
    var match = paymentList.mandate_payment;
    if (match !== undefined && paymentType !== 1 && paymentType < 7) {
      return callIntent(JSON.stringify(Js_dict.fromArray(body.concat(bodyArr.concat(PaymentBody$OrcaPaymentPage.mandateBody(undefined), BrowserSpec$OrcaPaymentPage.broswerInfo(undefined))))));
    } else {
      return intentWithoutMandate(undefined);
    }
  };
}

function useSessions(clientSecret, publishableKey, endpoint, walletsOpt, isDelayedSessionTokenOpt, optLogger, param) {
  var wallets = walletsOpt !== undefined ? walletsOpt : [];
  var isDelayedSessionToken = isDelayedSessionTokenOpt !== undefined ? isDelayedSessionTokenOpt : false;
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var paymentIntentID = Belt_Option.getWithDefault(Belt_Array.get(clientSecret.split("_secret_"), 0), "");
  var body = Js_dict.fromArray([
        [
          "payment_id",
          paymentIntentID
        ],
        [
          "client_secret",
          clientSecret
        ],
        [
          "wallets",
          wallets
        ],
        [
          "delayed_session_token",
          isDelayedSessionToken
        ]
      ]);
  var uri = endpoint + "/payments/session_tokens";
  LoggerUtils$OrcaPaymentPage.logApi(/* SESSIONS_CALL_INIT */15, undefined, undefined, "request", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
  return Promise$OrcaPaymentPage.$$catch(fetchApi(uri, JSON.stringify(body), Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers)), /* Post */2, undefined).then(function (resp) {
                    var statusCode = String(resp.status);
                    if (statusCode.charAt(0) !== "2") {
                      resp.json().then(function (data) {
                            LoggerUtils$OrcaPaymentPage.logApi(/* SESSIONS_CALL */16, statusCode, Caml_option.some(data), "err", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                            return Promise.resolve(undefined);
                          });
                    } else {
                      LoggerUtils$OrcaPaymentPage.logApi(/* SESSIONS_CALL */16, statusCode, undefined, "response", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
                    }
                    return resp.json();
                  }).then(function (json) {
                  return Promise.resolve(json);
                }), (function (err) {
                var exceptionJson = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                return prim.message;
                              })), OrcaUtils$OrcaPaymentPage.safeParseOpt), null);
                LoggerUtils$OrcaPaymentPage.logApi(/* SESSIONS_CALL */16, undefined, Caml_option.some(exceptionJson), "no_response", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                return Promise.resolve({});
              }));
}

function usePaymentMethodList(clientSecret, publishableKey, endpoint, logger) {
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/account/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi(/* PAYMENT_METHODS_CALL_INIT */17, undefined, undefined, "request", uri, undefined, undefined, logger, /* INFO */1, /* API */0, undefined);
  return Promise$OrcaPaymentPage.$$catch(fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers)), /* Get */0, undefined).then(function (resp) {
                    var statusCode = String(resp.status);
                    if (statusCode.charAt(0) !== "2") {
                      resp.json().then(function (data) {
                            LoggerUtils$OrcaPaymentPage.logApi(/* PAYMENT_METHODS_CALL */18, statusCode, Caml_option.some(data), "err", uri, undefined, undefined, logger, /* ERROR */2, /* API */0, undefined);
                            return Promise.resolve(undefined);
                          });
                    } else {
                      LoggerUtils$OrcaPaymentPage.logApi(/* PAYMENT_METHODS_CALL */18, statusCode, undefined, "response", uri, undefined, undefined, logger, /* INFO */1, /* API */0, undefined);
                    }
                    return resp.json();
                  }).then(function (json) {
                  return Promise.resolve(json);
                }), (function (err) {
                var exceptionJson = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                return prim.message;
                              })), OrcaUtils$OrcaPaymentPage.safeParseOpt), null);
                LoggerUtils$OrcaPaymentPage.logApi(/* PAYMENT_METHODS_CALL */18, undefined, Caml_option.some(exceptionJson), "no_response", uri, undefined, undefined, logger, /* ERROR */2, /* API */0, undefined);
                return Promise.resolve({});
              }));
}

function useCustomerDetails(clientSecret, publishableKey, endpoint, optLogger) {
  var fetchApi = function (param, param$1, param$2, param$3, param$4) {
    return CommonHooks$OrcaPaymentPage.useApiFetcher(undefined, param, param$1, param$2, param$3, param$4);
  };
  var headers = [
    [
      "Content-Type",
      "application/json"
    ],
    [
      "api-key",
      publishableKey
    ]
  ];
  var uri = endpoint + "/customers/payment_methods?client_secret=" + clientSecret;
  LoggerUtils$OrcaPaymentPage.logApi(/* CUSTOMER_PAYMENT_METHODS_CALL_INIT */19, undefined, undefined, "request", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
  return Promise$OrcaPaymentPage.$$catch(fetchApi(uri, undefined, Caml_option.some(ApiEndpoint$OrcaPaymentPage.addCustomPodHeader(headers)), /* Get */0, undefined).then(function (res) {
                    var statusCode = String(res.status);
                    if (statusCode.charAt(0) !== "2") {
                      res.json().then(function (data) {
                            LoggerUtils$OrcaPaymentPage.logApi(/* CUSTOMER_PAYMENT_METHODS_CALL */20, statusCode, Caml_option.some(data), "err", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                            return Promise.resolve(undefined);
                          });
                    } else {
                      LoggerUtils$OrcaPaymentPage.logApi(/* CUSTOMER_PAYMENT_METHODS_CALL */20, statusCode, undefined, "response", uri, undefined, undefined, optLogger, /* INFO */1, /* API */0, undefined);
                    }
                    return res.json();
                  }).then(function (res) {
                  return Promise.resolve(res);
                }), (function (err) {
                var exceptionJson = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                return prim.message;
                              })), OrcaUtils$OrcaPaymentPage.safeParseOpt), null);
                LoggerUtils$OrcaPaymentPage.logApi(/* CUSTOMER_PAYMENT_METHODS_CALL */20, undefined, Caml_option.some(exceptionJson), "no_response", uri, undefined, undefined, optLogger, /* ERROR */2, /* API */0, undefined);
                return Promise.resolve({});
              }));
}

function invokeKountSDK(fromScript) {
  var match = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.configAtom);
  var config = match.config;
  var match$1 = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.keys);
  var publishableKey = match$1.publishableKey;
  var kountEnabledKeys = GlobalVars$OrcaPaymentPage.isProd ? [
      "pk_prd_9fd3349624bd46dcb0800d9fe79d2e8c",
      "pk_prd_8120ac99fa40419fb86537904097c93d"
    ] : [
      "pk_snd_f54ef7b414f34ec58d01631462bf0275",
      "pk_snd_3b33cd9404234113804aa1accaabe22f",
      "pk_snd_457b57ec9c31496499aa26cb31f7732a"
    ];
  return function (param) {
    try {
      var kountConfig_environment = GlobalVars$OrcaPaymentPage.isProd ? "PROD" : "TEST";
      var kountConfig = {
        clientID: "700000",
        environment: kountConfig_environment,
        isSinglePageApp: false
      };
      var paymentId = Caml_array.get(config.clientSecret.split("_secret_"), 0);
      if (!kountEnabledKeys.includes(publishableKey)) {
        return ;
      }
      if (fromScript) {
        return Utils$OrcaPaymentPage.handlePostMessage(undefined, [
                    [
                      "invokeKountSDK",
                      true
                    ],
                    [
                      "kountSid",
                      paymentId
                    ]
                  ]);
      }
      var sdk = KountWebClientSdk(kountConfig, paymentId);
      console.log("Anti-fraud SDK activated!");
      var ddcStatus = function (param) {
        setTimeout((function (param) {
                console.log("DDC completed = " + Belt_Option.getWithDefault(JSON.stringify(sdk.IsCompleted()), ""));
                if (!sdk.IsCompleted()) {
                  return ddcStatus(undefined);
                }
                
              }), 4000);
        
      };
      return ddcStatus(undefined);
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      console.log(err, "KountSDK Error");
      return ;
    }
  };
}

export {
  closePaymentLoaderIfAny ,
  retrievePaymentIntent ,
  pollRetrievePaymentIntent ,
  intentCall ,
  usePaymentSync ,
  maskPayload ,
  usePaymentIntent ,
  useSessions ,
  usePaymentMethodList ,
  useCustomerDetails ,
  invokeKountSDK ,
  
}
/* recoil Not a pure module */
