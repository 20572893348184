// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";

var errorWarning = [
  [
    /* INVALID_PK */39,
    /* Error */0,
    {
      TAG: /* Static */1,
      _0: "INTEGRATION ERROR: Invalid Publishable key, starts with pk_snd_(sandbox/test) or pk_prd_(production/live)"
    }
  ],
  [
    /* DEPRECATED_LOADSTRIPE */40,
    /* Warning */1,
    {
      TAG: /* Static */1,
      _0: "loadStripe is deprecated. Please use loadHyper instead."
    }
  ],
  [
    /* REQUIRED_PARAMETER */41,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str + " is a required field/parameter or " + str + " cannot be empty";
        })
    }
  ],
  [
    /* UNKNOWN_KEY */42,
    /* Warning */1,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Unknown Key: " + str + " is a unknown/invalid key, please provide a correct key. This might cause issue in the future";
        })
    }
  ],
  [
    /* TYPE_BOOL_ERROR */44,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected boolean";
        })
    }
  ],
  [
    /* TYPE_STRING_ERROR */46,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected string";
        })
    }
  ],
  [
    /* TYPE_INT_ERROR */45,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Type Error: '" + str + "' Expected int";
        })
    }
  ],
  [
    /* VALUE_OUT_OF_RANGE */49,
    /* Warning */1,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Value out of range: '" + str + "'. Please provide a value inside the range";
        })
    }
  ],
  [
    /* UNKNOWN_VALUE */43,
    /* Warning */1,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "Unknown Value: " + str + ". Please provide a correct value. This might cause issue in the future";
        })
    }
  ],
  [
    /* SDK_CONNECTOR_WARNING */48,
    /* Warning */1,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str;
        })
    }
  ],
  [
    /* INVALID_FORMAT */47,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return str;
        })
    }
  ],
  [
    /* HTTP_NOT_ALLOWED */50,
    /* Error */0,
    {
      TAG: /* Dynamic */0,
      _0: (function (str) {
          return "INTEGRATION ERROR: " + str + " Serve your application over HTTPS. This is a requirement both in development and in production. One way to get up and running is to use a service like ngrok.";
        })
    }
  ],
  [
    /* INTERNAL_API_DOWN */51,
    /* Warning */1,
    {
      TAG: /* Static */1,
      _0: "LOAD ERROR: Something went wrong! Please try again or contact out dev support https://hyperswitch.io/docs/support"
    }
  ]
];

function manageErrorWarning(key, dynamicStrOpt, logger, param) {
  var dynamicStr = dynamicStrOpt !== undefined ? dynamicStrOpt : "";
  var entry = errorWarning.find(function (param) {
        return Caml_obj.caml_equal(param[0], key);
      });
  if (entry === undefined) {
    return ;
  }
  var str = entry[2];
  var key$1 = entry[0];
  if (entry[1]) {
    if (str.TAG === /* Dynamic */0) {
      var fn = str._0;
      Curry._7(logger.setLogError, Curry._1(fn, dynamicStr), key$1, undefined, /* WARNING */3, /* USER_ERROR */1, undefined, undefined);
      console.warn(Curry._1(fn, dynamicStr));
      return ;
    }
    var string = str._0;
    Curry._7(logger.setLogError, string, key$1, undefined, /* WARNING */3, /* USER_ERROR */1, undefined, undefined);
    console.warn(string);
    return ;
  }
  if (str.TAG === /* Dynamic */0) {
    var fn$1 = str._0;
    Curry._7(logger.setLogError, Curry._1(fn$1, dynamicStr), key$1, undefined, /* ERROR */2, /* USER_ERROR */1, undefined, undefined);
    console.error(Curry._1(fn$1, dynamicStr));
    return Js_exn.raiseError(Curry._1(fn$1, dynamicStr));
  }
  var string$1 = str._0;
  Curry._7(logger.setLogError, string$1, key$1, undefined, /* ERROR */2, /* USER_ERROR */1, undefined, undefined);
  console.error(string$1);
  return Js_exn.raiseError(string$1);
}

function unknownKeysWarning(validKeysArr, dict, dictType, logger) {
  Js_dict.entries(dict).forEach(function (param) {
        var key = param[0];
        if (validKeysArr.includes(key)) {
          return ;
        } else {
          return manageErrorWarning(/* UNKNOWN_KEY */42, "'" + key + "' key in " + dictType, logger, undefined);
        }
      });
  
}

function unknownPropValueWarning(inValidValue, validValueArr, dictType) {
  var expectedValues = validValueArr.map(function (item) {
          return "'" + item + "'";
        }).join(", ");
  var partial_arg = "'" + inValidValue + "' value in " + dictType + ", Expected " + expectedValues;
  return function (param) {
    var param$1;
    return manageErrorWarning(/* UNKNOWN_VALUE */43, partial_arg, param, param$1);
  };
}

function valueOutRangeWarning(num, dictType, range) {
  var partial_arg = String(num) + " value in " + dictType + " Expected value between " + range;
  return function (param) {
    var param$1;
    return manageErrorWarning(/* VALUE_OUT_OF_RANGE */49, partial_arg, param, param$1);
  };
}

export {
  errorWarning ,
  manageErrorWarning ,
  unknownKeysWarning ,
  unknownPropValueWarning ,
  valueOutRangeWarning ,
  
}
/* No side effect */
