// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as Promise$OrcaPaymentPage from "../libraries/Promise.bs.js";

function getHeaders(uri, token, headersOpt, param) {
  var headers = headersOpt !== undefined ? Caml_option.valFromOption(headersOpt) : ({});
  var headerObj = Js_dict.fromArray([
        [
          "Content-Type",
          "application/json"
        ],
        [
          "sdk-version",
          Window$OrcaPaymentPage.version
        ]
      ]);
  if (token !== undefined && uri !== undefined) {
    headerObj["Authorization"] = token;
  }
  Js_dict.entries(headers).forEach(function (entries) {
        headerObj[entries[0]] = entries[1];
        
      });
  return headerObj;
}

function useApiFetcher(param, uri, bodyStrOpt, headersOpt, method_, param$1) {
  var bodyStr = bodyStrOpt !== undefined ? bodyStrOpt : "";
  var headers = headersOpt !== undefined ? Caml_option.valFromOption(headersOpt) : ({});
  var body = method_ === 0 ? Promise.resolve(undefined) : Promise.resolve(Caml_option.some(bodyStr));
  return body.then(function (body) {
              return Promise$OrcaPaymentPage.$$catch(fetch(uri, Fetch.RequestInit.make(method_, Caml_option.some(getHeaders(uri, undefined, Caml_option.some(headers), undefined)), body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)), (function (err) {
                              return Promise.reject(err);
                            })).then(function (resp) {
                          return Promise.resolve(resp);
                        });
            });
}

function useScript(src) {
  var match = React.useState(function () {
        if (src !== "") {
          return "loading";
        } else {
          return "idle";
        }
      });
  var setStatus = match[1];
  React.useEffect((function () {
          if (src === "") {
            Curry._1(setStatus, (function (param) {
                    return "idle";
                  }));
          }
          var script = document.querySelector("script[src=\"" + src + "\"]");
          if (script == null) {
            var script$1 = document.createElement("script");
            script$1.src = src;
            script$1.async = true;
            script$1.setAttribute("data-status", "loading");
            document.body.appendChild(script$1);
            var setAttributeFromEvent = function ($$event) {
              Curry._1(setStatus, (function (param) {
                      if ($$event.type === "load") {
                        return "ready";
                      } else {
                        return "error";
                      }
                    }));
              return script$1.setAttribute("data-status", $$event.type === "load" ? "ready" : "error");
            };
            script$1.addEventListener("load", setAttributeFromEvent);
            script$1.addEventListener("error", setAttributeFromEvent);
            return (function (param) {
                      script$1.removeEventListener("load", setAttributeFromEvent);
                      script$1.removeEventListener("error", setAttributeFromEvent);
                      
                    });
          }
          Curry._1(setStatus, (function (param) {
                  return script.getAttribute("data-status");
                }));
          
        }), [src]);
  return match[0];
}

function updateKeys(dict, keyPair, setKeys) {
  var value = keyPair[1];
  var key = keyPair[0];
  var valueStr = Belt_Option.getWithDefault(Js_json.decodeString(value), "");
  var valueBool = Belt_Option.getWithDefault(Js_json.decodeBoolean(value), false);
  if (!Utils$OrcaPaymentPage.getDictIsSome(dict, key)) {
    return ;
  }
  switch (key) {
    case "iframeId" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "parentURL" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: prev.iframeId,
                            parentURL: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "publishableKey" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: Utils$OrcaPaymentPage.getString(dict, key, valueStr),
                            iframeId: prev.iframeId,
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: prev.sdkHandleConfirmPayment
                          };
                  });
    case "sdkHandleConfirmPayment" :
        return setKeys(function (prev) {
                    return {
                            clientSecret: prev.clientSecret,
                            publishableKey: prev.publishableKey,
                            iframeId: prev.iframeId,
                            parentURL: prev.parentURL,
                            sdkHandleConfirmPayment: Utils$OrcaPaymentPage.getBool(dict, key, valueBool)
                          };
                  });
    default:
      return ;
  }
}

var defaultkeys = {
  clientSecret: undefined,
  publishableKey: "",
  iframeId: "",
  parentURL: "*",
  sdkHandleConfirmPayment: false
};

export {
  getHeaders ,
  useApiFetcher ,
  useScript ,
  updateKeys ,
  defaultkeys ,
  
}
/* react Not a pure module */
