// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as OrcaUtils$OrcaPaymentPage from "./OrcaUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";

function make(componentType, options, setIframeRef, iframeRef, mountPostMessage) {
  try {
    var mountId = {
      contents: ""
    };
    var sdkHandleConfirmPayment = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(options), (function (x) {
                    return Js_dict.get(x, "sdkHandleConfirmPayment");
                  })), Js_json.decodeBoolean), false);
    var disableSaveCards = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(options), (function (x) {
                    return Js_dict.get(x, "disableSaveCards");
                  })), Js_json.decodeBoolean), false);
    var on = function (eventType, eventHandler) {
      var match = Types$OrcaPaymentPage.eventTypeMapper(eventType);
      switch (match) {
        case /* Escape */0 :
            return EventListenerManager$OrcaPaymentPage.addSmartEventListener("keypress", (function (ev) {
                          if (ev.key === "Escape" && eventHandler !== undefined) {
                            return Curry._1(eventHandler, ev.data);
                          }
                          
                        }), "onEscape");
        case /* Change */1 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.elementType === componentType;
                        }), eventHandler, /* Change */1, "onChange");
        case /* Click */2 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.clickTriggered;
                        }), eventHandler, /* Click */2, "onClick");
        case /* Ready */3 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.ready;
                        }), eventHandler, /* Ready */3, "onReady");
        case /* Focus */4 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.focus;
                        }), eventHandler, /* Focus */4, "onFocus");
        case /* Blur */5 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.blur;
                        }), eventHandler, /* Blur */5, "onBlur");
        case /* ConfirmPayment */6 :
            return OrcaUtils$OrcaPaymentPage.eventHandlerFunc((function (ev) {
                          return ev.data.confirmTriggered;
                        }), eventHandler, /* ConfirmPayment */6, "onHelpConfirmPayment");
        case /* None */7 :
            return ;
        
      }
    };
    var collapse = function (param) {
      
    };
    var blur = function (param) {
      iframeRef.forEach(function (iframe) {
            var message = Js_dict.fromArray([[
                    "doBlur",
                    true
                  ]]);
            return Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
      
    };
    var focus = function (param) {
      iframeRef.forEach(function (iframe) {
            var message = Js_dict.fromArray([[
                    "doFocus",
                    true
                  ]]);
            return Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
      
    };
    var clear = function (param) {
      iframeRef.forEach(function (iframe) {
            var message = Js_dict.fromArray([[
                    "doClearValues",
                    true
                  ]]);
            return Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
      
    };
    var unmount = function (param) {
      var id = mountId.contents;
      var oElement = document.querySelector(id);
      if (oElement == null) {
        console.warn("INTEGRATION ERROR: Div does not seem to exist on which payment element is to mount/unmount");
      } else {
        oElement.innerHTML = "";
      }
      
    };
    var destroy = function (param) {
      unmount(undefined);
      mountId.contents = "";
      
    };
    var update = function (newOptions) {
      var flatOption = OrcaUtils$OrcaPaymentPage.flattenObject(options, true);
      var newFlatOption = OrcaUtils$OrcaPaymentPage.flattenObject(newOptions, true);
      var keys = Object.keys(flatOption);
      keys.forEach(function (key) {
            var op = Js_dict.get(newFlatOption, key);
            if (op !== undefined) {
              flatOption[key] = Caml_option.valFromOption(op);
              return ;
            }
            
          });
      var newEntries = Js_dict.entries(newFlatOption);
      newEntries.forEach(function (entries) {
            var key = entries[0];
            if (Belt_Option.isNone(Js_dict.get(flatOption, key))) {
              flatOption[key] = entries[1];
              return ;
            }
            
          });
      iframeRef.forEach(function (iframe) {
            var message = Js_dict.fromArray([
                  [
                    "paymentElementsUpdate",
                    true
                  ],
                  [
                    "options",
                    OrcaUtils$OrcaPaymentPage.unflattenObject(flatOption)
                  ]
                ]);
            return Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
      
    };
    var mount = function (selector) {
      mountId.contents = selector;
      var localSelectorArr = selector.split("#");
      var localSelectorString = Belt_Option.getWithDefault(Belt_Array.get(localSelectorArr, 1), "someString");
      var iframeHeightRef = {
        contents: 25.0
      };
      var currentClass = {
        contents: "base"
      };
      var fullscreen = {
        contents: false
      };
      var fullscreenParam = {
        contents: ""
      };
      var fullscreenMetadata = {
        contents: {}
      };
      var optionsDict = Utils$OrcaPaymentPage.getDictFromJson(options);
      var handle = function (ev) {
        var eventDataObject = ev.data;
        var iframeHeight = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeHeight");
        if (Belt_Option.isSome(iframeHeight)) {
          var iframeId = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeId"), "no-element");
          iframeHeightRef.contents = OrcaUtils$OrcaPaymentPage.getFloatfromjson(iframeHeight, 200.0);
          if (iframeId === localSelectorString) {
            var elem = document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString);
            if (!(elem == null)) {
              elem.style.height = (
                iframeHeightRef.contents !== 0.0 ? String(iframeHeightRef.contents) : "200"
              ) + "px";
            }
            
          }
          
        }
        var isInvoke = OrcaUtils$OrcaPaymentPage.getBoolfromjson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "invokeKountSDK"), false);
        var kountSid = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "kountSid"), "");
        if (isInvoke && kountSid !== "") {
          var kountUrl = "https://" + (
            GlobalVars$OrcaPaymentPage.isProd ? "ssl" : "tst"
          ) + ".kaptcha.com/collect/sdk?m=700000&s=" + kountSid;
          if (document.querySelectorAll("script[src=\"" + kountUrl + "\"]").length === 0) {
            var script = document.createElement("script");
            script.src = kountUrl;
            script.onerror = (function (err) {
                console.log("ERROR DURING LOADING KOUNT DDC SDK", err);
                
              });
            document.body.appendChild(script);
            try {
              script.onload = (function (param) {
                  var sdk = new ka.ClientSDK();
                  sdk.collectData();
                  console.log("Anti-fraud SDK activated!");
                  
                });
            }
            catch (raw_err){
              var err = Caml_js_exceptions.internalToOCamlException(raw_err);
              console.log(err, "KountSDK Error");
            }
          }
          
        }
        var val = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "openurl");
        if (val !== undefined) {
          var url = OrcaUtils$OrcaPaymentPage.getStringfromjson(Caml_option.valFromOption(val), "");
          window.location.replace(url);
        }
        var isCopy = OrcaUtils$OrcaPaymentPage.getBoolfromjson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "copy"), false);
        var text = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "copyDetails"), "");
        if (isCopy) {
          navigator.clipboard.writeText(text).then(function (param) {
                return Promise.resolve(undefined);
              });
        }
        var combinedHyperClasses = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "concatedString");
        if (Belt_Option.isSome(combinedHyperClasses)) {
          var id = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "id"), "");
          var decodeStringTest = Belt_Option.flatMap(combinedHyperClasses, Js_json.decodeString);
          if (decodeStringTest !== undefined) {
            currentClass.contents = decodeStringTest;
          }
          if (id === localSelectorString) {
            var elem$1 = document.querySelector("#orca-element-" + localSelectorString);
            if (!(elem$1 == null)) {
              elem$1.className = currentClass.contents;
            }
            
          }
          
        }
        var iframeMounted = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeMounted");
        var fullscreenIframe = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "fullscreen");
        var param = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "param");
        var metadata = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "metadata");
        var iframeID = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(eventDataObject, "iframeId"), "");
        if (Belt_Option.isSome(fullscreenIframe)) {
          fullscreen.contents = OrcaUtils$OrcaPaymentPage.getBoolfromjson(fullscreenIframe, false);
          fullscreenParam.contents = OrcaUtils$OrcaPaymentPage.getStringfromOptionaljson(param, "");
          fullscreenMetadata.contents = Belt_Option.getWithDefault(Belt_Option.flatMap(metadata, Js_json.decodeObject), {});
          var fullscreenElem = document.querySelector("#orca-fullscreen-iframeRef-" + localSelectorString);
          if (!(fullscreenElem == null)) {
            fullscreenElem.innerHTML = "";
            var mainElement = document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString);
            var iframeURL = fullscreenParam.contents !== "" ? "https://checkout.hyperswitch.io" + publicPath + "/index.html?fullscreenType=" + fullscreenParam.contents : "https://checkout.hyperswitch.io" + publicPath + "/index.html?fullscreenType=fullscreen";
            if (fullscreen.contents) {
              if (iframeID === localSelectorString) {
                var handleFullScreenCallback = function (ev) {
                  var json = ev.data;
                  var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                  if (!Belt_Option.isSome(Js_dict.get(dict, "iframeMountedCallback"))) {
                    return ;
                  }
                  var fullScreenEle = document.querySelector("#orca-fullscreen");
                  return Window$OrcaPaymentPage.iframePostMessage(fullScreenEle, Js_dict.fromArray([
                                  [
                                    "fullScreenIframeMounted",
                                    true
                                  ],
                                  [
                                    "metadata",
                                    fullscreenMetadata.contents
                                  ]
                                ]));
                };
                EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleFullScreenCallback, "onFullScreenCallback");
                OrcaUtils$OrcaPaymentPage.makeIframe(fullscreenElem, iframeURL).then(function (param) {
                      return Window$OrcaPaymentPage.iframePostMessage(mainElement, Js_dict.fromArray([[
                                        "fullScreenIframeMounted",
                                        true
                                      ]]));
                    });
              }
              
            } else {
              fullscreenElem.innerHTML = "";
              Window$OrcaPaymentPage.iframePostMessage(mainElement, Js_dict.fromArray([[
                          "fullScreenIframeMounted",
                          false
                        ]]));
            }
          }
          
        }
        if (Belt_Option.isSome(iframeMounted)) {
          return Curry._4(mountPostMessage, document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString), localSelectorString, sdkHandleConfirmPayment, disableSaveCards);
        }
        
      };
      EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handle, "onMount");
      var oElement = document.querySelector(selector);
      var classesBase = OrcaUtils$OrcaPaymentPage.getClasses(optionsDict, "base");
      if (oElement == null) {
        return ;
      }
      var iframeDiv = "<div id=\"orca-element-" + localSelectorString + "\" style= \"height: auto;\"  class=\"" + componentType + " " + currentClass.contents + " " + classesBase + " \">\n          <div id=\"orca-fullscreen-iframeRef-" + localSelectorString + "\"></div>\n           <iframe\n           id =\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n           name=\"orca-payment-element-iframeRef-" + localSelectorString + "\"\n          src=\"https://checkout.hyperswitch.io" + publicPath + "/index.html?componentName=" + componentType + "\"\n          allow=\"payment *\"\n          name=\"orca-payment\"\n          style=\"border: 0px;\"\n          width=\"100%\"\n        ></iframe>\n        </div>";
      oElement.innerHTML = iframeDiv;
      Curry._1(setIframeRef, document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString));
      var elem = document.querySelector("#orca-payment-element-iframeRef-" + localSelectorString);
      if (!(elem == null)) {
        elem.style.transition = "height 0.35s ease 0s, opacity 0.4s ease 0.1s";
        return ;
      }
      
    };
    return {
            on: on,
            collapse: collapse,
            blur: blur,
            update: update,
            destroy: destroy,
            unmount: unmount,
            mount: mount,
            focus: focus,
            clear: clear
          };
  }
  catch (_e){
    return Types$OrcaPaymentPage.defaultPaymentElement;
  }
}

export {
  make ,
  
}
/* Types-OrcaPaymentPage Not a pure module */
