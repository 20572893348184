// Generated by ReScript, PLEASE EDIT WITH CARE


var isSandbox = window.location.hostname === "beta.hyperswitch.io";

var isInteg = window.location.hostname === "integ.hyperswitch.io";

var isProd = window.location.hostname === "checkout.hyperswitch.io";

var targetOrigin = "*";

var sentryDSN = "https://47483766e76f4846a8023bfbe088f244@sentry.juspay.in//6";

export {
  targetOrigin ,
  isSandbox ,
  isInteg ,
  isProd ,
  sentryDSN ,
  
}
/* isSandbox Not a pure module */
