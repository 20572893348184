// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./Utilities/ErrorUtils.bs.js";
import * as DefaultTheme$OrcaPaymentPage from "./DefaultTheme.bs.js";
import * as LocaleString$OrcaPaymentPage from "./LocaleString.bs.js";

function getTheme(val, logger) {
  switch (val) {
    case "brutal" :
        return /* Brutal */1;
    case "charcoal" :
        return /* Charcoal */4;
    case "default" :
        return /* Default */0;
    case "midnight" :
        return /* Midnight */2;
    case "none" :
        return /* NONE */5;
    case "soft" :
        return /* Soft */3;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(val, [
              "default",
              "midnight",
              "brutal",
              "charcoal",
              "soft",
              "none"
            ], "appearance.theme")(logger);
      return /* Default */0;
  }
}

function getShowLoader(str, logger) {
  switch (str) {
    case "always" :
        return /* Always */1;
    case "auto" :
        return /* Auto */0;
    case "never" :
        return /* Never */2;
    default:
      ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
              "auto",
              "always",
              "never"
            ], "loader")(logger);
      return /* Auto */0;
  }
}

function getPaymentMode(val) {
  switch (val) {
    case "card" :
        return /* Card */0;
    case "cardCvc" :
        return /* CardCVCElement */4;
    case "cardExpiry" :
        return /* CardExpiryElement */3;
    case "cardNumber" :
        return /* CardNumberElement */2;
    case "payment" :
        return /* Payment */1;
    default:
      return /* NONE */5;
  }
}

var defaultAppearance_rules = {};

var defaultAppearance = {
  theme: /* Default */0,
  componentType: "payment",
  variables: DefaultTheme$OrcaPaymentPage.$$default,
  rules: defaultAppearance_rules,
  labels: /* Above */0
};

var defaultConfig_fonts = [];

var defaultConfig = {
  appearance: defaultAppearance,
  locale: "auto",
  clientSecret: "",
  fonts: defaultConfig_fonts,
  loader: /* Auto */0
};

function getLocaleObject(string) {
  var val = string === "auto" ? navigator.language : string;
  return Belt_Option.getWithDefault(Belt_Array.get(LocaleString$OrcaPaymentPage.localeStrings.filter(function (item) {
                      return item.locale === val;
                    }), 0), LocaleString$OrcaPaymentPage.defaultLocale);
}

var defaultRecoilConfig_localeString = getLocaleObject("auto");

var defaultRecoilConfig = {
  config: defaultConfig,
  themeObj: DefaultTheme$OrcaPaymentPage.$$default,
  localeString: defaultRecoilConfig_localeString,
  showLoader: false
};

function getVariables(str, dict, $$default, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    var validKeys = [
                      "fontFamily",
                      "fontSizeBase",
                      "colorPrimary",
                      "colorBackground",
                      "colorText",
                      "colorDanger",
                      "colorDangerText",
                      "borderRadius",
                      "fontVariantLigatures",
                      "fontVariationSettings",
                      "spacingUnit",
                      "fontWeightLight",
                      "fontWeightNormal",
                      "fontWeightMedium",
                      "fontWeightBold",
                      "fontLineHeight",
                      "fontSizeXl",
                      "fontSizeLg",
                      "fontSizeSm",
                      "fontSizeXs",
                      "fontSize2Xs",
                      "fontSize3Xs",
                      "colorSuccess",
                      "colorWarning",
                      "colorPrimaryText",
                      "colorBackgroundText",
                      "colorSuccessText",
                      "colorWarningText",
                      "colorTextSecondary",
                      "colorTextPlaceholder",
                      "spacingTab",
                      "borderColor",
                      "spacingGridColumn",
                      "spacingGridRow",
                      "spacingAccordionItem"
                    ];
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning(validKeys, json, "appearance.variables", logger);
                    return {
                            fontFamily: Utils$OrcaPaymentPage.getWarningString(json, "fontFamily", $$default.fontFamily, logger),
                            fontSizeBase: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeBase", $$default.fontSizeBase, logger),
                            colorPrimary: Utils$OrcaPaymentPage.getWarningString(json, "colorPrimary", $$default.colorPrimary, logger),
                            colorBackground: Utils$OrcaPaymentPage.getWarningString(json, "colorBackground", $$default.colorBackground, logger),
                            colorText: Utils$OrcaPaymentPage.getWarningString(json, "colorText", $$default.colorText, logger),
                            colorDanger: Utils$OrcaPaymentPage.getWarningString(json, "colorDanger", $$default.colorDanger, logger),
                            borderRadius: Utils$OrcaPaymentPage.getWarningString(json, "borderRadius", $$default.borderRadius, logger),
                            fontVariantLigatures: Utils$OrcaPaymentPage.getWarningString(json, "fontVariantLigatures", $$default.fontVariantLigatures, logger),
                            fontVariationSettings: Utils$OrcaPaymentPage.getWarningString(json, "fontVariationSettings", $$default.fontVariationSettings, logger),
                            spacingUnit: Utils$OrcaPaymentPage.getWarningString(json, "spacingUnit", $$default.spacingUnit, logger),
                            fontWeightLight: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightLight", $$default.fontWeightLight, logger),
                            fontWeightNormal: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightNormal", $$default.fontWeightNormal, logger),
                            fontWeightMedium: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightMedium", $$default.fontWeightMedium, logger),
                            fontWeightBold: Utils$OrcaPaymentPage.getWarningString(json, "fontWeightBold", $$default.fontWeightBold, logger),
                            fontLineHeight: Utils$OrcaPaymentPage.getWarningString(json, "fontLineHeight", $$default.fontLineHeight, logger),
                            fontSizeXl: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeXl", $$default.fontSizeXl, logger),
                            fontSizeLg: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeLg", $$default.fontSizeLg, logger),
                            fontSizeSm: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeSm", $$default.fontSizeSm, logger),
                            fontSizeXs: Utils$OrcaPaymentPage.getWarningString(json, "fontSizeXs", $$default.fontSizeXs, logger),
                            fontSize2Xs: Utils$OrcaPaymentPage.getWarningString(json, "fontSize2Xs", $$default.fontSize2Xs, logger),
                            fontSize3Xs: Utils$OrcaPaymentPage.getWarningString(json, "fontSize3Xs", $$default.fontSize3Xs, logger),
                            colorSuccess: Utils$OrcaPaymentPage.getWarningString(json, "colorSuccess", $$default.colorSuccess, logger),
                            colorWarning: Utils$OrcaPaymentPage.getWarningString(json, "colorWarning", $$default.colorWarning, logger),
                            colorPrimaryText: Utils$OrcaPaymentPage.getWarningString(json, "colorPrimaryText", $$default.colorPrimaryText, logger),
                            colorBackgroundText: Utils$OrcaPaymentPage.getWarningString(json, "colorBackgroundText", $$default.colorBackgroundText, logger),
                            colorSuccessText: Utils$OrcaPaymentPage.getWarningString(json, "colorSuccessText", $$default.colorSuccessText, logger),
                            colorDangerText: Utils$OrcaPaymentPage.getWarningString(json, "colorDangerText", $$default.colorDangerText, logger),
                            colorWarningText: Utils$OrcaPaymentPage.getWarningString(json, "colorWarningText", $$default.colorWarningText, logger),
                            colorTextSecondary: Utils$OrcaPaymentPage.getWarningString(json, "colorTextSecondary", $$default.colorTextSecondary, logger),
                            colorTextPlaceholder: Utils$OrcaPaymentPage.getWarningString(json, "colorTextPlaceholder", $$default.colorTextPlaceholder, logger),
                            spacingTab: Utils$OrcaPaymentPage.getWarningString(json, "spacingTab", $$default.spacingTab, logger),
                            borderColor: Utils$OrcaPaymentPage.getWarningString(json, "borderColor", $$default.borderColor, logger),
                            spacingAccordionItem: Utils$OrcaPaymentPage.getWarningString(json, "spacingAccordionItem", $$default.spacingAccordionItem, logger),
                            colorIconCardCvc: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardCvc", $$default.colorIconCardCvc, logger),
                            colorIconCardCvcError: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardCvcError", $$default.colorIconCardCvcError, logger),
                            colorIconCardError: Utils$OrcaPaymentPage.getWarningString(json, "colorIconCardError", $$default.colorIconCardError, logger),
                            spacingGridColumn: Utils$OrcaPaymentPage.getWarningString(json, "spacingGridColumn", $$default.spacingGridColumn, logger),
                            spacingGridRow: Utils$OrcaPaymentPage.getWarningString(json, "spacingGridRow", $$default.spacingGridRow, logger)
                          };
                  })), $$default);
}

function getAppearance(str, dict, $$default, defaultRules, logger) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                    ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                          "theme",
                          "variables",
                          "rules",
                          "labels"
                        ], json, "appearance", logger);
                    var rulesJson = Curry._1(defaultRules, getVariables("variables", json, $$default, logger));
                    var str = Utils$OrcaPaymentPage.getWarningString(json, "labels", "above", logger);
                    var tmp;
                    switch (str) {
                      case "above" :
                          tmp = /* Above */0;
                          break;
                      case "floating" :
                          tmp = /* Floating */1;
                          break;
                      default:
                        ErrorUtils$OrcaPaymentPage.unknownPropValueWarning(str, [
                                "above",
                                "floating"
                              ], "appearance.labels")(logger);
                        tmp = /* Above */0;
                    }
                    return {
                            theme: getTheme(Utils$OrcaPaymentPage.getWarningString(json, "theme", "default", logger), logger),
                            componentType: Utils$OrcaPaymentPage.getWarningString(json, "componentType", "", logger),
                            variables: getVariables("variables", json, $$default, logger),
                            rules: Utils$OrcaPaymentPage.mergeJsons(rulesJson, Utils$OrcaPaymentPage.getJsonObjectFromDict(json, "rules")),
                            labels: tmp
                          };
                  })), defaultAppearance);
}

function getFonts(str, dict, logger) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
                    "cssSrc",
                    "family",
                    "src",
                    "weight"
                  ], json, "fonts", logger);
              return {
                      cssSrc: Utils$OrcaPaymentPage.getWarningString(json, "cssSrc", "", logger),
                      family: Utils$OrcaPaymentPage.getWarningString(json, "family", "", logger),
                      src: Utils$OrcaPaymentPage.getWarningString(json, "src", "", logger),
                      weight: Utils$OrcaPaymentPage.getWarningString(json, "weight", "", logger)
                    };
            });
}

function itemToObjMapper(dict, $$default, defaultRules, logger) {
  ErrorUtils$OrcaPaymentPage.unknownKeysWarning([
        "appearance",
        "fonts",
        "locale",
        "clientSecret",
        "loader"
      ], dict, "elements", logger);
  return {
          appearance: getAppearance("appearance", dict, $$default, defaultRules, logger),
          locale: Utils$OrcaPaymentPage.getWarningString(dict, "locale", "auto", logger),
          clientSecret: Utils$OrcaPaymentPage.getWarningString(dict, "clientSecret", "", logger),
          fonts: getFonts("fonts", dict, logger),
          loader: getShowLoader(Utils$OrcaPaymentPage.getWarningString(dict, "loader", "auto", logger), logger)
        };
}

var defaultFonts = {
  cssSrc: "",
  family: "",
  src: "",
  weight: ""
};

export {
  getTheme ,
  getShowLoader ,
  getPaymentMode ,
  defaultAppearance ,
  defaultFonts ,
  defaultConfig ,
  getLocaleObject ,
  defaultRecoilConfig ,
  getVariables ,
  getAppearance ,
  getFonts ,
  itemToObjMapper ,
  
}
/* defaultRecoilConfig Not a pure module */
