// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$OrcaPaymentPage from "../Icon.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as BlikCodePaymentInput$OrcaPaymentPage from "../Components/BlikCodePaymentInput.bs.js";
import * as PhoneNumberPaymentInput$OrcaPaymentPage from "../Components/PhoneNumberPaymentInput.bs.js";

var defaultPaymentMethodContent_fields = [];

var defaultPaymentMethodContent_paymentFlow = [];

var defaultPaymentMethodContent_bankNames = [];

var defaultPaymentMethodContent = {
  paymentMethodName: "",
  fields: defaultPaymentMethodContent_fields,
  paymentFlow: defaultPaymentMethodContent_paymentFlow,
  handleUserError: false,
  methodType: "",
  bankNames: defaultPaymentMethodContent_bankNames
};

function icon(sizeOpt, widthOpt, name) {
  var size = sizeOpt !== undefined ? sizeOpt : 22;
  var width = widthOpt !== undefined ? widthOpt : size;
  return React.createElement(Icon$OrcaPaymentPage.make, {
              name: name,
              size: size,
              width: width
            });
}

var paymentMethodsFields = [
  {
    paymentMethodName: "afterpay_clearpay",
    fields: [
      /* Email */0,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "afterpay")),
    displayName: "After Pay"
  },
  {
    paymentMethodName: "mb_way",
    fields: [
      {
        TAG: /* SpecialField */0,
        _0: React.createElement(PhoneNumberPaymentInput$OrcaPaymentPage.make, {})
      },
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "mbway")),
    displayName: "Mb Way"
  },
  {
    paymentMethodName: "mobile_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "mobilepay")),
    displayName: "Mobile Pay"
  },
  {
    paymentMethodName: "ali_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "alipay")),
    displayName: "Ali Pay"
  },
  {
    paymentMethodName: "we_chat_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "wechatpay")),
    displayName: "WeChat"
  },
  {
    paymentMethodName: "affirm",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(20, undefined, "affirm")),
    displayName: "Affirm"
  },
  {
    paymentMethodName: "crypto_currency",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "crypto")),
    displayName: "Crypto"
  },
  {
    paymentMethodName: "card",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "default-card")),
    displayName: "Card"
  },
  {
    paymentMethodName: "klarna",
    fields: [
      /* Email */0,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "klarna")),
    displayName: "Klarna"
  },
  {
    paymentMethodName: "sofort",
    fields: [
      /* FullName */1,
      /* Email */0,
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "sofort")),
    displayName: "Sofort"
  },
  {
    paymentMethodName: "ach_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Bank Transfer"
  },
  {
    paymentMethodName: "bacs_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "bank")),
    displayName: "BACS Bank Transfer"
  },
  {
    paymentMethodName: "sepa_transfer",
    fields: [],
    icon: Caml_option.some(icon(19, undefined, "sepa")),
    displayName: "SEPA Bank Transfer"
  },
  {
    paymentMethodName: "sepa_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "sepa")),
    displayName: "SEPA Debit"
  },
  {
    paymentMethodName: "giropay",
    fields: [
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "giropay")),
    displayName: "GiroPay"
  },
  {
    paymentMethodName: "eps",
    fields: [
      /* Bank */4,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "eps")),
    displayName: "EPS"
  },
  {
    paymentMethodName: "walley",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "walley")),
    displayName: "Walley"
  },
  {
    paymentMethodName: "pay_bright",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "paybright")),
    displayName: "Pay Bright"
  },
  {
    paymentMethodName: "ach_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "ach")),
    displayName: "ACH Debit"
  },
  {
    paymentMethodName: "bacs_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BACS Debit"
  },
  {
    paymentMethodName: "becs_debit",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(21, undefined, "bank")),
    displayName: "BECS Debit"
  },
  {
    paymentMethodName: "blik",
    fields: [
      {
        TAG: /* SpecialField */0,
        _0: React.createElement(BlikCodePaymentInput$OrcaPaymentPage.make, {})
      },
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "blik")),
    displayName: "Blik"
  },
  {
    paymentMethodName: "trustly",
    fields: [
      /* Country */3,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "trustly")),
    displayName: "Trustly"
  },
  {
    paymentMethodName: "bancontact_card",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, 25, "bancontact")),
    displayName: "Bancontact Card"
  },
  {
    paymentMethodName: "online_banking_czech_republic",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking CzechR"
  },
  {
    paymentMethodName: "online_banking_slovakia",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Slovakia"
  },
  {
    paymentMethodName: "online_banking_finland",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Finland"
  },
  {
    paymentMethodName: "online_banking_poland",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "bank")),
    displayName: "Online Banking Poland"
  },
  {
    paymentMethodName: "ideal",
    fields: [
      /* Bank */4,
      /* FullName */1,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, 25, "ideal")),
    displayName: "iDEAL"
  },
  {
    paymentMethodName: "ban_connect",
    fields: [],
    icon: undefined,
    displayName: "Ban Connect"
  },
  {
    paymentMethodName: "ach_bank_debit",
    fields: [],
    icon: Caml_option.some(icon(19, 25, "ach-bank-debit")),
    displayName: "ACH Direct Debit"
  },
  {
    paymentMethodName: "przelewy24",
    fields: [
      /* Email */0,
      /* Bank */4
    ],
    icon: Caml_option.some(icon(19, undefined, "p24")),
    displayName: "Przelewy24"
  },
  {
    paymentMethodName: "interac",
    fields: [
      /* Email */0,
      /* Country */3
    ],
    icon: Caml_option.some(icon(19, undefined, "interac")),
    displayName: "Interac"
  },
  {
    paymentMethodName: "twint",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "twint")),
    displayName: "Twint"
  },
  {
    paymentMethodName: "vipps",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "vipps")),
    displayName: "Vipps"
  },
  {
    paymentMethodName: "dana",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "dana")),
    displayName: "Dana"
  },
  {
    paymentMethodName: "go_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "go_pay")),
    displayName: "Go Pay"
  },
  {
    paymentMethodName: "kakao_pay",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "kakao_pay")),
    displayName: "Kakao Pay"
  },
  {
    paymentMethodName: "gcash",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "gcash")),
    displayName: "GCash"
  },
  {
    paymentMethodName: "momo",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "momo")),
    displayName: "Momo"
  },
  {
    paymentMethodName: "touch_n_go",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "touch_n_go")),
    displayName: "Touch N Go"
  },
  {
    paymentMethodName: "bizum",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "bizum")),
    displayName: "Bizum"
  },
  {
    paymentMethodName: "classic",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(50, undefined, "cashtocode")),
    displayName: "Cash / Voucher"
  },
  {
    paymentMethodName: "online_banking_fpx",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_fpx")),
    displayName: "Online Banking Fpx"
  },
  {
    paymentMethodName: "online_banking_thailand",
    fields: [
      /* Bank */4,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "online_banking_thailand")),
    displayName: "Online Banking Thailand"
  },
  {
    paymentMethodName: "alma",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "alma")),
    displayName: "Alma"
  },
  {
    paymentMethodName: "atome",
    fields: [/* InfoElement */2],
    icon: Caml_option.some(icon(19, undefined, "atome")),
    displayName: "Atome"
  },
  {
    paymentMethodName: "multibanco",
    fields: [
      /* Email */0,
      /* InfoElement */2
    ],
    icon: Caml_option.some(icon(19, undefined, "multibanco")),
    displayName: "Multibanco"
  }
];

function getPaymentMethodsFieldTypeFromString(str) {
  switch (str) {
    case "user_address_city" :
        return /* AddressCity */10;
    case "user_address_country" :
        return /* AddressCountry */13;
    case "user_address_line1" :
        return /* AddressLine1 */8;
    case "user_address_line2" :
        return /* AddressLine2 */9;
    case "user_address_pincode" :
        return /* AddressPincode */11;
    case "user_address_state" :
        return /* AddressState */12;
    case "user_bank" :
        return /* Bank */4;
    case "user_blik_code" :
        return /* BlikCode */14;
    case "user_country" :
        return /* Country */3;
    case "user_email_address" :
        return /* Email */0;
    case "user_full_name" :
        return /* FullName */1;
    case "user_phone_number" :
        return /* PhoneNumber */7;
    default:
      return /* None */5;
  }
}

function getPaymentMethodsFieldTypeFromDict(dict) {
  var keysArr = Object.keys(dict);
  var key = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(keysArr.find(function (item) {
                return item === "user_currency";
              })), "");
  if (key !== "user_currency") {
    return /* None */5;
  }
  var options = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "user_currency"), Js_json.decodeObject), {}), "options"), Js_json.decodeArray), []), Js_json.decodeString);
  return {
          TAG: /* Currency */1,
          _0: options
        };
}

function getFieldType(dict) {
  var fieldClass = Js_json.classify(Belt_Option.getWithDefault(Js_dict.get(dict, "field_type"), {}));
  if (typeof fieldClass === "number") {
    return /* None */5;
  }
  switch (fieldClass.TAG | 0) {
    case /* JSONString */0 :
        return getPaymentMethodsFieldTypeFromString(fieldClass._0);
    case /* JSONObject */2 :
        return getPaymentMethodsFieldTypeFromDict(fieldClass._0);
    case /* JSONNumber */1 :
    case /* JSONArray */3 :
        return /* None */5;
    
  }
}

function getRequiredFieldsFromJson(dict) {
  return {
          required_field: Utils$OrcaPaymentPage.getString(dict, "required_field", ""),
          display_name: Utils$OrcaPaymentPage.getString(dict, "display_name", ""),
          field_type: getFieldType(dict),
          value: Utils$OrcaPaymentPage.getString(dict, "value", "")
        };
}

function getPaymentMethodFields(paymentMethod, requiredFields) {
  var requiredFieldsArr = paymentMethod === "crypto_currency" ? Js_dict.values(Utils$OrcaPaymentPage.getDictFromJson(requiredFields)).map(function (item) {
          return getRequiredFieldsFromJson(Utils$OrcaPaymentPage.getDictFromJson(item)).field_type;
        }) : [];
  return requiredFieldsArr.concat(Belt_Option.getWithDefault(Caml_option.undefined_to_opt(paymentMethodsFields.find(function (x) {
                          return x.paymentMethodName === paymentMethod;
                        })), {
                  paymentMethodName: "",
                  fields: [],
                  icon: Caml_option.some(icon(19, 25, "")),
                  displayName: ""
                }).fields);
}

function getPaymentDetails(arr) {
  var finalArr = [];
  arr.map(function (item) {
        var optionalVal = paymentMethodsFields.find(function (i) {
              return i.paymentMethodName === item;
            });
        if (optionalVal !== undefined) {
          finalArr.push(optionalVal);
          return ;
        }
        
      });
  return finalArr;
}

var defaultPaymentMethodType_payment_experience = [];

var defaultPaymentMethodType_card_networks = [];

var defaultPaymentMethodType_bank_names = [];

var defaultPaymentMethodType_bank_debits_connectors = [];

var defaultPaymentMethodType_bank_transfers_connectors = [];

var defaultPaymentMethodType = {
  payment_method_type: "",
  payment_experience: defaultPaymentMethodType_payment_experience,
  card_networks: defaultPaymentMethodType_card_networks,
  bank_names: defaultPaymentMethodType_bank_names,
  bank_debits_connectors: defaultPaymentMethodType_bank_debits_connectors,
  bank_transfers_connectors: defaultPaymentMethodType_bank_transfers_connectors,
  required_fields: null
};

var defaultList_payment_methods = [];

var defaultList = {
  redirect_url: "",
  payment_methods: defaultList_payment_methods,
  mandate_payment: undefined
};

function getMethod(str) {
  switch (str) {
    case "bank_debit" :
        return /* BankDebit */5;
    case "bank_redirect" :
        return /* BankRedirect */3;
    case "bank_transfer" :
        return /* BankTransfer */4;
    case "card" :
        return /* Cards */0;
    case "crypto" :
        return /* Crypto */6;
    case "pay_later" :
        return /* PayLater */2;
    case "wallet" :
        return /* Wallets */1;
    default:
      return /* NONE */7;
  }
}

function getPaymentMethodType(str) {
  switch (str) {
    case "affirm" :
        return /* Affirm */1;
    case "afterpay_clearpay" :
        return /* AfterPay */2;
    case "apple_pay" :
        return /* ApplePay */5;
    case "credit" :
        return /* Card */{
                _0: /* Credit */0
              };
    case "crypto_currency" :
        return /* CryptoCurrency */6;
    case "debit" :
        return /* Card */{
                _0: /* Debit */1
              };
    case "google_pay" :
        return /* Gpay */3;
    case "klarna" :
        return /* Klarna */0;
    default:
      return /* NONE */7;
  }
}

function getPaymentExperienceType(str) {
  switch (str) {
    case "display_qr_code" :
        return /* QrFlow */2;
    case "invoke_sdk_client" :
        return /* InvokeSDK */0;
    case "redirect_to_url" :
        return /* RedirectToURL */1;
    default:
      return /* RedirectToURL */1;
  }
}

function getPaymentExperience(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_experience_type: getPaymentExperienceType(Utils$OrcaPaymentPage.getString(json, "payment_experience_type", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors")
                    };
            });
}

function getCardNetworks(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      card_network: CardUtils$OrcaPaymentPage.cardType(Utils$OrcaPaymentPage.getString(json, "card_network", "")),
                      eligible_connectors: Utils$OrcaPaymentPage.getStrArray(json, "eligible_connectors")
                    };
            });
}

function getBankNames(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
                return Utils$OrcaPaymentPage.getStrArray(json, "bank_name");
              }).reduce((function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                      
                    });
                return acc;
              }), []);
}

function getAchConnectors(dict, str) {
  return Utils$OrcaPaymentPage.getStrArray(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), {}), "elligible_connectors");
}

function getPaymentMethodTypes(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_method_type: Utils$OrcaPaymentPage.getString(json, "payment_method_type", ""),
                      payment_experience: getPaymentExperience(json, "payment_experience"),
                      card_networks: getCardNetworks(json, "card_networks"),
                      bank_names: getBankNames(json, "bank_names"),
                      bank_debits_connectors: getAchConnectors(json, "bank_debit"),
                      bank_transfers_connectors: getAchConnectors(json, "bank_transfer"),
                      required_fields: Utils$OrcaPaymentPage.getJsonFromDict(json, "required_fields", null)
                    };
            });
}

function getMethodsArr(dict, str) {
  return Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeArray), []), Js_json.decodeObject).map(function (json) {
              return {
                      payment_method: Utils$OrcaPaymentPage.getString(json, "payment_method", ""),
                      payment_method_types: getPaymentMethodTypes(json, "payment_method_types")
                    };
            });
}

function getOptionalMandateType(dict, str) {
  return Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                return {
                        amount: Utils$OrcaPaymentPage.getInt(json, "amount", 0),
                        currency: Utils$OrcaPaymentPage.getString(json, "currency", "")
                      };
              }));
}

function getMandate(dict, str) {
  return Belt_Option.map(Belt_Option.flatMap(Js_dict.get(dict, str), Js_json.decodeObject), (function (json) {
                return {
                        single_use: getOptionalMandateType(json, "single_use"),
                        multi_use: getOptionalMandateType(json, "multi_use")
                      };
              }));
}

function itemToObjMapper(dict) {
  return {
          redirect_url: Utils$OrcaPaymentPage.getString(dict, "redirect_url", ""),
          payment_methods: getMethodsArr(dict, "payment_methods"),
          mandate_payment: getMandate(dict, "mandate_payment")
        };
}

function buildFromPaymentList(plist) {
  var paymentMethodArr = plist.payment_methods;
  return paymentMethodArr.map(function (paymentMethodObject) {
                var methodType = paymentMethodObject.payment_method;
                var handleUserError = methodType === "wallet";
                return paymentMethodObject.payment_method_types.map(function (individualPaymentMethod) {
                            var paymentMethodName = individualPaymentMethod.payment_method_type;
                            var bankNames = individualPaymentMethod.bank_names;
                            var paymentExperience = individualPaymentMethod.payment_experience.map(function (experience) {
                                  return [
                                          experience.payment_experience_type,
                                          experience.eligible_connectors
                                        ];
                                });
                            return {
                                    paymentMethodName: paymentMethodName,
                                    fields: getPaymentMethodFields(paymentMethodName, individualPaymentMethod.required_fields),
                                    paymentFlow: paymentExperience,
                                    handleUserError: handleUserError,
                                    methodType: methodType,
                                    bankNames: bankNames
                                  };
                          });
              }).reduce((function (acc, item) {
                item.forEach(function (obj) {
                      acc.push(obj);
                      
                    });
                return acc;
              }), []);
}

function getPaymentMethodTypeFromList(list, paymentMethod, paymentMethodType) {
  return Caml_option.undefined_to_opt(Belt_Option.getWithDefault(Caml_option.undefined_to_opt(list.payment_methods.find(function (item) {
                            return item.payment_method === paymentMethod;
                          })), {
                    payment_method: "card",
                    payment_method_types: []
                  }).payment_method_types.find(function (item) {
                  return item.payment_method_type === paymentMethodType;
                }));
}

export {
  defaultPaymentMethodContent ,
  icon ,
  paymentMethodsFields ,
  getPaymentMethodsFieldTypeFromString ,
  getPaymentMethodsFieldTypeFromDict ,
  getFieldType ,
  getRequiredFieldsFromJson ,
  getPaymentMethodFields ,
  getPaymentDetails ,
  defaultPaymentMethodType ,
  defaultList ,
  getMethod ,
  getPaymentMethodType ,
  getPaymentExperienceType ,
  getPaymentExperience ,
  getCardNetworks ,
  getBankNames ,
  getAchConnectors ,
  getPaymentMethodTypes ,
  getMethodsArr ,
  getOptionalMandateType ,
  getMandate ,
  itemToObjMapper ,
  buildFromPaymentList ,
  getPaymentMethodTypeFromList ,
  
}
/* paymentMethodsFields Not a pure module */
