// Generated by ReScript, PLEASE EDIT WITH CARE


var defaultDict = {};

export {
  defaultDict ,
  
}
/* No side effect */
