// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Recoil from "recoil";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Recoil$OrcaPaymentPage from "../libraries/Recoil.bs.js";
import * as RenderIf$OrcaPaymentPage from "./RenderIf.bs.js";
import * as CardUtils$OrcaPaymentPage from "../CardUtils.bs.js";
import * as ConfirmType$OrcaPaymentPage from "../Types/ConfirmType.bs.js";
import * as RecoilAtoms$OrcaPaymentPage from "../Utilities/RecoilAtoms.bs.js";
import * as PaymentField$OrcaPaymentPage from "./PaymentField.bs.js";

function BlikCodePaymentInput(Props) {
  var loggerState = Recoil.useRecoilValue(RecoilAtoms$OrcaPaymentPage.loggerAtom);
  var match = Recoil$OrcaPaymentPage.useLoggedRecoilState(RecoilAtoms$OrcaPaymentPage.userBlikCode, "blikCode", loggerState);
  var setblikCode = match[1];
  var blikCode = match[0];
  var blikCodeRef = React.useRef(null);
  var formatBSB = function (bsb) {
    var formatted = bsb.replace(/\D+/g, "");
    var firstPart = CardUtils$OrcaPaymentPage.slice(formatted, 0, 3);
    var secondPart = CardUtils$OrcaPaymentPage.slice(formatted, 3, 6);
    if (formatted.length <= 3) {
      return firstPart;
    } else if (formatted.length > 3 && formatted.length <= 6) {
      return firstPart + "-" + secondPart;
    } else {
      return formatted;
    }
  };
  var changeblikCode = function (ev) {
    var val = ev.target.value;
    return setblikCode(function (prev) {
                return {
                        value: formatBSB(val),
                        isValid: prev.isValid,
                        errorString: prev.errorString
                      };
              });
  };
  React.useEffect((function () {
          setblikCode(function (prev) {
                var val = prev.isValid;
                return {
                        value: prev.value,
                        isValid: prev.isValid,
                        errorString: val !== undefined && !val ? "Invalid blikCode" : ""
                      };
              });
          
        }), [blikCode.isValid]);
  var submitCallback = React.useCallback((function (ev) {
          var json = JSON.parse(ev.data);
          var confirm = ConfirmType$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json));
          if (confirm.doSubmit && blikCode.value === "") {
            return setblikCode(function (prev) {
                        return {
                                value: prev.value,
                                isValid: prev.isValid,
                                errorString: "blikCode cannot be empty"
                              };
                      });
          }
          
        }), [blikCode]);
  Utils$OrcaPaymentPage.submitPaymentData(submitCallback);
  return React.createElement(RenderIf$OrcaPaymentPage.make, {
              condition: true,
              children: React.createElement(PaymentField$OrcaPaymentPage.make, {
                    setValue: setblikCode,
                    value: blikCode,
                    onChange: changeblikCode,
                    fieldName: "Blik code",
                    name: "blikCode",
                    type_: "blikCode",
                    paymentType: /* Payment */1,
                    maxLength: 7,
                    placeholder: "000 000",
                    inputRef: blikCodeRef
                  })
            });
}

var make = BlikCodePaymentInput;

export {
  make ,
  
}
/* react Not a pure module */
