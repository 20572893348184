// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as Promise$OrcaPaymentPage from "../libraries/Promise.bs.js";
import * as OrcaUtils$OrcaPaymentPage from "./OrcaUtils.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as SessionsType$OrcaPaymentPage from "../Types/SessionsType.bs.js";
import * as ApplePayTypes$OrcaPaymentPage from "../Types/ApplePayTypes.bs.js";
import * as GooglePayType$OrcaPaymentPage from "../Types/GooglePayType.bs.js";
import * as PaymentHelpers$OrcaPaymentPage from "../Utilities/PaymentHelpers.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";
import * as LoaderPaymentElement$OrcaPaymentPage from "./LoaderPaymentElement.bs.js";
import * as PaymentMethodsRecord$OrcaPaymentPage from "../Payments/PaymentMethodsRecord.bs.js";

function make(options, setIframeRef, clientSecretId, sdkSessionIdOpt, publishableKey, applePaySessionRef, handleApplePayMessages, logger) {
  var sdkSessionId = sdkSessionIdOpt !== undefined ? sdkSessionIdOpt : "";
  try {
    var iframeRef = [];
    var logger$1 = Belt_Option.getWithDefault(logger, OrcaLogger$OrcaPaymentPage.defaultLoggerConfig);
    var savedPaymentElement = {};
    var localOptions = Belt_Option.getWithDefault(Js_json.decodeObject(options), {});
    var testMode = publishableKey.startsWith("pk_snd_");
    var endpoint = testMode ? "https://sandbox.hyperswitch.io" : "https://api.hyperswitch.io";
    var appearance = Belt_Option.getWithDefault(Js_dict.get(localOptions, "appearance"), {});
    var fonts = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(localOptions, "fonts"), Js_json.decodeArray), []);
    var sessionsPromise = PaymentHelpers$OrcaPaymentPage.useSessions(clientSecretId, publishableKey, endpoint, undefined, undefined, logger$1, undefined);
    var paymentMethodListPromise = PaymentHelpers$OrcaPaymentPage.usePaymentMethodList(clientSecretId, publishableKey, endpoint, logger$1);
    var locale = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "locale", "");
    var loader = Utils$OrcaPaymentPage.getJsonStringFromDict(localOptions, "loader", "");
    var clientSecret = Utils$OrcaPaymentPage.getRequiredString(localOptions, "clientSecret", "", logger$1);
    var clientSecretReMatch = new RegExp(".+_secret_[A-Za-z0-9]+").test(clientSecret);
    var fetchPaymentsList = function (mountedIframeRef) {
      paymentMethodListPromise.then(function (json) {
            var isApplePayPresent = Belt_Option.isSome(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json)), "wallet", "apple_pay"));
            var isGooglePayPresent = Belt_Option.isSome(PaymentMethodsRecord$OrcaPaymentPage.getPaymentMethodTypeFromList(PaymentMethodsRecord$OrcaPaymentPage.itemToObjMapper(Utils$OrcaPaymentPage.getDictFromJson(json)), "wallet", "google_pay"));
            if ((isApplePayPresent || isGooglePayPresent) && document.querySelectorAll("script[src=\"https://tpgw.trustpay.eu/js/v1.js\"]").length === 0 && document.querySelectorAll("script[src=\"https://test-tpgw.trustpay.eu/js/v1.js\"]").length === 0) {
              var trustPayScriptURL = publishableKey.startsWith("pk_prd_") ? "https://tpgw.trustpay.eu/js/v1.js" : "https://test-tpgw.trustpay.eu/js/v1.js";
              var trustPayScript = document.createElement("script");
              trustPayScript.src = trustPayScriptURL;
              trustPayScript.onerror = (function (err) {
                  return Utils$OrcaPaymentPage.logInfo((console.log("ERROR DURING LOADING TRUSTPAY APPLE PAY", err), undefined));
                });
              document.body.appendChild(trustPayScript);
              Curry._7(logger$1.setLogInfo, "TrustPay Script Loaded", /* TRUSTPAY_SCRIPT */21, undefined, undefined, undefined, undefined, undefined);
              if (isGooglePayPresent && document.querySelectorAll("script[src=\"https://pay.google.com/gp/p/js/pay.js\"]").length === 0) {
                var googlePayScript = document.createElement("script");
                googlePayScript.src = "https://pay.google.com/gp/p/js/pay.js";
                googlePayScript.onerror = (function (err) {
                    return Utils$OrcaPaymentPage.logInfo((console.log("ERROR DURING LOADING GOOGLE PAY SCRIPT", err), undefined));
                  });
                document.body.appendChild(googlePayScript);
                Curry._7(logger$1.setLogInfo, "GooglePay Script Loaded", /* GOOGLE_PAY_SCRIPT */22, undefined, undefined, undefined, undefined, undefined);
              }
              
            }
            var msg = Js_dict.fromArray([[
                    "paymentMethodList",
                    json
                  ]]);
            Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
            return Promise.resolve(json);
          });
      
    };
    var fetchCustomerDetails = function (mountedIframeRef) {
      var customerDetailsPromise = PaymentHelpers$OrcaPaymentPage.useCustomerDetails(clientSecretId, publishableKey, endpoint, logger$1);
      Promise$OrcaPaymentPage.$$catch(customerDetailsPromise.then(function (json) {
                var msg = Js_dict.fromArray([[
                        "customerPaymentMethods",
                        json
                      ]]);
                Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                return Promise.resolve(json);
              }), (function (_err) {
              var dict = Js_dict.fromArray([[
                      "customer_payment_methods",
                      []
                    ]]);
              var msg = Js_dict.fromArray([[
                      "customerPaymentMethods",
                      dict
                    ]]);
              Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
              return Promise.resolve(msg);
            }));
      
    };
    if (!clientSecretReMatch) {
      ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* INVALID_FORMAT */47, "clientSecret is expected to be in format ******_secret_*****", logger$1, undefined);
    }
    var setElementIframeRef = function (ref) {
      iframeRef.push(ref);
      return Curry._1(setIframeRef, ref);
    };
    var getElement = function (componentName) {
      return Js_dict.get(savedPaymentElement, componentName);
    };
    var update = function (newOptions) {
      var newOptionsDict = Utils$OrcaPaymentPage.getDictFromJson(newOptions);
      var val = Js_dict.get(newOptionsDict, "locale");
      if (val !== undefined) {
        localOptions["locale"] = Caml_option.valFromOption(val);
      }
      var val$1 = Js_dict.get(newOptionsDict, "appearance");
      if (val$1 !== undefined) {
        localOptions["appearance"] = Caml_option.valFromOption(val$1);
      }
      var val$2 = Js_dict.get(newOptionsDict, "clientSecret");
      if (val$2 !== undefined) {
        localOptions["clientSecret"] = Caml_option.valFromOption(val$2);
      }
      iframeRef.forEach(function (iframe) {
            var message = Js_dict.fromArray([
                  [
                    "ElementsUpdate",
                    true
                  ],
                  [
                    "options",
                    newOptionsDict
                  ]
                ]);
            return Window$OrcaPaymentPage.iframePostMessage(iframe, message);
          });
      
    };
    var fetchUpdates = function (param) {
      return new Promise((function (resolve, param) {
                    setTimeout((function (param) {
                            return resolve({});
                          }), 1000);
                    
                  }));
    };
    var create = function (componentType, newOptions) {
      if (componentType === "") {
        ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* REQUIRED_PARAMETER */41, "type", logger$1, undefined);
      }
      var otherElements = componentType === "card" || componentType === "cardNumber" || componentType === "cardExpiry" || componentType === "cardCvc";
      switch (componentType) {
        case "card" :
        case "cardCvc" :
        case "cardExpiry" :
        case "cardNumber" :
        case "payment" :
            break;
        default:
          ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* UNKNOWN_KEY */42, componentType + " type in create", logger$1, undefined);
      }
      var mountPostMessage = function (mountedIframeRef, selectorString, sdkHandleConfirmPayment, disableSaveCards) {
        var widgetOptions = Js_dict.fromArray([
              [
                "clientSecret",
                clientSecret
              ],
              [
                "appearance",
                appearance
              ],
              [
                "locale",
                locale
              ],
              [
                "loader",
                loader
              ],
              [
                "fonts",
                fonts
              ]
            ]);
        var message = Js_dict.fromArray([
              [
                "paymentElementCreate",
                componentType === "payment"
              ],
              [
                "otherElements",
                otherElements
              ],
              [
                "options",
                newOptions
              ],
              [
                "componentType",
                componentType
              ],
              [
                "paymentOptions",
                widgetOptions
              ],
              [
                "iframeId",
                selectorString
              ],
              [
                "publishableKey",
                publishableKey
              ],
              [
                "sdkSessionId",
                sdkSessionId
              ],
              [
                "sdkHandleConfirmPayment",
                sdkHandleConfirmPayment
              ],
              [
                "parentURL",
                "*"
              ]
            ]);
        var handleApplePayMounted = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          if (!Belt_Option.isSome(Js_dict.get(dict, "applePayMounted"))) {
            return ;
          }
          var session = window.ApplePaySession;
          if (session == null) {
            return ;
          }
          if (!session.canMakePayments()) {
            return Utils$OrcaPaymentPage.logInfo((console.log("CANNOT MAKE PAYMENT USING APPLE PAY"), undefined));
          }
          var msg = Js_dict.fromArray([[
                  "applePayCanMakePayments",
                  true
                ]]);
          return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
        };
        var handleGooglePayThirdPartyFlow = function ($$event) {
          var json = $$event.data;
          var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
          var googlePayThirdPartyOptSession = Js_dict.get(dict, "googlePayThirdPartyFlow");
          if (googlePayThirdPartyOptSession !== undefined) {
            var googlePayThirdPartySession = Utils$OrcaPaymentPage.getDictFromJson(Caml_option.valFromOption(googlePayThirdPartyOptSession));
            var baseDetails = {
              apiVersion: 2,
              apiVersionMinor: 0,
              environment: GlobalVars$OrcaPaymentPage.isProd ? "PRODUCTION" : "TEST"
            };
            var paymentDataRequest = Object.assign({}, baseDetails);
            var googlePayRequest = GooglePayType$OrcaPaymentPage.jsonToPaymentRequestDataType(paymentDataRequest, googlePayThirdPartySession);
            var secrets = Utils$OrcaPaymentPage.getJsonFromDict(googlePayThirdPartySession, "secrets", null);
            var payment = Utils$OrcaPaymentPage.getString(Utils$OrcaPaymentPage.getDictFromJson(secrets), "payment", "");
            try {
              var trustpay = new TrustPayApi(secrets);
              Promise$OrcaPaymentPage.$$catch(trustpay.executeGooglePayment(payment, googlePayRequest).then(function (res) {
                        Curry._7(logger$1.setLogInfo, "TrustPay GooglePay Success Response: " + JSON.stringify(res), /* GOOGLE_PAY_FLOW */24, undefined, undefined, undefined, "GOOGLE_PAY", undefined);
                        var msg = Js_dict.fromArray([[
                                "googlePaySyncPayment",
                                true
                              ]]);
                        Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                        return Promise.resolve(undefined);
                      }), (function (err) {
                      var exceptionMessage = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                  return prim.message;
                                })), "TrustPay GooglePay Error");
                      Curry._7(logger$1.setLogInfo, exceptionMessage, /* GOOGLE_PAY_FLOW */24, undefined, /* ERROR */2, /* USER_ERROR */1, "GOOGLE_PAY", undefined);
                      var msg = Js_dict.fromArray([[
                              "googlePaySyncPayment",
                              true
                            ]]);
                      Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                      return Promise.resolve(undefined);
                    }));
            }
            catch (exn){
              Curry._7(logger$1.setLogInfo, "TrustPay API initialising error", /* GOOGLE_PAY_FLOW */24, undefined, undefined, undefined, "GOOGLE_PAY", undefined);
              var msg = Js_dict.fromArray([[
                      "googlePaySyncPayment",
                      true
                    ]]);
              Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
            }
          }
          var gpayClicked = OrcaUtils$OrcaPaymentPage.getBoolfromjson(OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(json, "GpayClicked"), false);
          var gpayPaymentRequestData = OrcaUtils$OrcaPaymentPage.getOptionalJsonFromJson(json, "gpayPaymentRequestData");
          if (!gpayClicked) {
            return ;
          }
          var gPayClient = new google.payments.api.PaymentsClient({
                environment: publishableKey.startsWith("pk_prd_") ? "PRODUCTION" : "TEST"
              });
          var data = gpayPaymentRequestData !== undefined ? Caml_option.valFromOption(gpayPaymentRequestData) : ({});
          try {
            Promise$OrcaPaymentPage.$$catch(gPayClient.loadPaymentData(data).then(function (json) {
                      Curry._7(logger$1.setLogInfo, JSON.stringify(json), /* GOOGLE_PAY_FLOW */24, undefined, /* DEBUG */0, undefined, "GOOGLE_PAY", undefined);
                      var msg = Js_dict.fromArray([[
                              "gpayResponse",
                              json
                            ]]);
                      Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                      return Promise.resolve(undefined);
                    }), (function (err) {
                    Curry._7(logger$1.setLogInfo, JSON.stringify(err), /* GOOGLE_PAY_FLOW */24, undefined, /* DEBUG */0, undefined, "GOOGLE_PAY", undefined);
                    var msg = Js_dict.fromArray([[
                            "gpayResponse",
                            err
                          ]]);
                    Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                    return Promise.resolve(undefined);
                  }));
            return ;
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            Curry._7(logger$1.setLogInfo, JSON.stringify(err), /* GOOGLE_PAY_FLOW */24, undefined, /* DEBUG */0, undefined, "GOOGLE_PAY", undefined);
            var msg$1 = Js_dict.fromArray([[
                    "gpayErorr",
                    err
                  ]]);
            return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg$1);
          }
        };
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleApplePayMounted, "onApplePayMount");
        EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleGooglePayThirdPartyFlow, "onGooglePayThirdParty");
        window.removeEventListener("message", handleApplePayMessages.contents);
        sessionsPromise.then(function (json) {
                  var sessionsArr = SessionsType$OrcaPaymentPage.getSessionsTokenJson(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "session_token");
                  var applePayPresent = sessionsArr.find(function (item) {
                        var x = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(item), (function (x) {
                                        return Js_dict.get(x, "wallet_name");
                                      })), Js_json.decodeString), "");
                        if (x === "apple_pay") {
                          return true;
                        } else {
                          return x === "applepay";
                        }
                      });
                  return Promise.resolve([
                              json,
                              applePayPresent === undefined ? undefined : Caml_option.some(applePayPresent)
                            ]);
                }).then(function (res) {
                var applePayPresent = res[1];
                if (componentType === "payment" && Belt_Option.isSome(applePayPresent)) {
                  handleApplePayMessages.contents = (function ($$event) {
                      var json = $$event.data;
                      var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                      var val = Js_dict.get(dict, "applePayButtonClicked");
                      if (val === undefined) {
                        return ;
                      }
                      if (!Belt_Option.getWithDefault(Js_json.decodeBoolean(Caml_option.valFromOption(val)), false)) {
                        return ;
                      }
                      var isDelayedSessionToken = Belt_Option.getWithDefault(Js_json.decodeBoolean(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Belt_Option.flatMap(applePayPresent, Js_json.decodeObject), {}), "delayed_session_token"), null)), false);
                      if (isDelayedSessionToken) {
                        Curry._7(logger$1.setLogInfo, "Delayed Session Token Flow", /* APPLE_PAY_FLOW */23, undefined, undefined, undefined, "APPLE_PAY", undefined);
                        var applePayPresent$1 = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "applePayPresent"), Js_json.decodeObject), {});
                        var connector = Belt_Option.getWithDefault(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(applePayPresent$1, "connector"), null)), "");
                        if (connector !== "trustpay") {
                          return ;
                        }
                        Curry._7(logger$1.setLogInfo, "TrustPay Connector Flow", /* APPLE_PAY_FLOW */23, undefined, undefined, undefined, "APPLE_PAY", undefined);
                        var secrets = Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(Belt_Option.getWithDefault(Js_dict.get(applePayPresent$1, "session_token_data"), null)), {}), "secrets"), null);
                        var paymentRequest = ApplePayTypes$OrcaPaymentPage.jsonToPaymentRequestDataType(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(applePayPresent$1, "payment_request_data"), Js_json.decodeObject), {}));
                        var payment = Belt_Option.getWithDefault(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(secrets), {}), "payment"), null)), "");
                        try {
                          var trustpay = new TrustPayApi(secrets);
                          Promise$OrcaPaymentPage.$$catch(trustpay.finishApplePaymentV2(payment, paymentRequest).then(function (res) {
                                    Curry._7(logger$1.setLogInfo, "TrustPay ApplePay Success Response: " + JSON.stringify(res), /* APPLE_PAY_FLOW */23, undefined, undefined, undefined, "APPLE_PAY", undefined);
                                    var msg = Js_dict.fromArray([[
                                            "applePaySyncPayment",
                                            true
                                          ]]);
                                    Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                                    return Promise.resolve(undefined);
                                  }), (function (err) {
                                  var exceptionMessage = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(err), (function (prim) {
                                              return prim.message;
                                            })), "TrustPay ApplePay Error");
                                  Curry._7(logger$1.setLogInfo, exceptionMessage, /* APPLE_PAY_FLOW */23, undefined, undefined, undefined, "APPLE_PAY", undefined);
                                  var msg = Js_dict.fromArray([[
                                          "applePaySyncPayment",
                                          true
                                        ]]);
                                  Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                                  return Promise.resolve(undefined);
                                }));
                          return ;
                        }
                        catch (exn){
                          Curry._7(logger$1.setLogInfo, "TrustPay API initialising error", /* APPLE_PAY_FLOW */23, undefined, undefined, undefined, "APPLE_PAY", undefined);
                          var msg = Js_dict.fromArray([[
                                  "applePaySyncPayment",
                                  true
                                ]]);
                          return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                        }
                      } else {
                        var paymentRequest$1 = Utils$OrcaPaymentPage.transformKeys(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Belt_Option.flatMap(applePayPresent, Js_json.decodeObject), {}), "payment_request_data"), {}), /* CamelCase */0);
                        var ssn = new ApplePaySession(3, paymentRequest$1);
                        var session = applePaySessionRef.contents;
                        if (!(session == null)) {
                          try {
                            session.abort();
                          }
                          catch (raw_error){
                            var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                            console.log("Abort fail", error);
                          }
                        }
                        ssn.begin();
                        applePaySessionRef.contents = ssn;
                        ssn.onvalidatemerchant = (function (_event) {
                            var merchantSession = Utils$OrcaPaymentPage.transformKeys(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Belt_Option.flatMap(applePayPresent, Js_json.decodeObject), {}), "session_token_data"), {}), /* CamelCase */0);
                            return ssn.completeMerchantValidation(merchantSession);
                          });
                        ssn.onpaymentauthorized = (function ($$event) {
                            ssn.completePayment({
                                  status: ssn.STATUS_SUCCESS
                                });
                            applePaySessionRef.contents = null;
                            var token = $$event.payment.token;
                            var msg = Js_dict.fromArray([[
                                    "applePayProcessPayment",
                                    token
                                  ]]);
                            return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                          });
                        ssn.oncancel = (function (_ev) {
                            var msg = Js_dict.fromArray([[
                                    "showApplePayButton",
                                    true
                                  ]]);
                            Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
                            applePaySessionRef.contents = null;
                            return Utils$OrcaPaymentPage.logInfo((console.log("Apple Pay payment cancelled"), undefined));
                          });
                        return ;
                      }
                    });
                  EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleApplePayMessages.contents, "onApplePayMessages");
                }
                return Promise.resolve(res[0]);
              }).then(function (json) {
              var msg = Js_dict.fromArray([[
                      "sessions",
                      json
                    ]]);
              Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, msg);
              return Promise.resolve(json);
            });
        fetchPaymentsList(mountedIframeRef);
        if (disableSaveCards) {
          
        } else {
          fetchCustomerDetails(mountedIframeRef);
        }
        return Window$OrcaPaymentPage.iframePostMessage(mountedIframeRef, message);
      };
      var paymentElement = LoaderPaymentElement$OrcaPaymentPage.make(componentType, newOptions, setElementIframeRef, iframeRef, mountPostMessage);
      savedPaymentElement[componentType] = paymentElement;
      return paymentElement;
    };
    return {
            getElement: getElement,
            update: update,
            fetchUpdates: fetchUpdates,
            create: create
          };
  }
  catch (_e){
    return Types$OrcaPaymentPage.defaultElement;
  }
}

export {
  make ,
  
}
/* Types-OrcaPaymentPage Not a pure module */
