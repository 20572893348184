// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";

var endpoint = "https://sandbox.hyperswitch.io";

var addCustomPodHeader = Js_dict.fromArray;

var switchToCustomPod = false;

var switchToInteg = false;

export {
  switchToCustomPod ,
  switchToInteg ,
  endpoint ,
  addCustomPodHeader ,
  
}
/* No side effect */
