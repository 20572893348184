// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Phone_numberJson from "./Phone_number.json";
import * as Country$OrcaPaymentPage from "../Country.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "./ErrorUtils.bs.js";
import * as GlobalVars$OrcaPaymentPage from "../GlobalVars.bs.js";
import * as PaymentDetails$OrcaPaymentPage from "../PaymentDetails.bs.js";
import * as PostalCodeType$OrcaPaymentPage from "../Types/PostalCodeType.bs.js";

var phoneNumberJson = Phone_numberJson;

function handlePostMessage(targetOriginOpt, messageArr) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  window.parent.postMessage(Js_dict.fromArray(messageArr), targetOrigin);
  
}

function handleOnFocusPostMessage(targetOriginOpt, param) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  return handlePostMessage(targetOrigin, [[
                "focus",
                true
              ]]);
}

function handleOnBlurPostMessage(targetOriginOpt, param) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  return handlePostMessage(targetOrigin, [[
                "blur",
                true
              ]]);
}

function handleOnClickPostMessage(targetOriginOpt, ev) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  return handlePostMessage(targetOrigin, [
              [
                "clickTriggered",
                true
              ],
              [
                "event",
                JSON.stringify(ev)
              ]
            ]);
}

function handleOnConfirmPostMessage(targetOriginOpt, param) {
  var targetOrigin = targetOriginOpt !== undefined ? targetOriginOpt : "*";
  return handlePostMessage(targetOrigin, [[
                "confirmTriggered",
                true
              ]]);
}

function getOptionString(dict, key) {
  return Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeString);
}

function getString(dict, key, $$default) {
  return Belt_Option.getWithDefault(getOptionString(dict, key), $$default);
}

function getInt(dict, key, $$default) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeNumber), $$default) | 0;
}

function getJsonBoolValue(dict, key, $$default) {
  return Belt_Option.getWithDefault(Js_dict.get(dict, key), $$default);
}

function getJsonStringFromDict(dict, key, $$default) {
  return Belt_Option.getWithDefault(Js_dict.get(dict, key), $$default);
}

function getJsonArrayFromDict(dict, key, $$default) {
  return Belt_Option.getWithDefault(Js_dict.get(dict, key), $$default);
}

function getJsonFromDict(dict, key, $$default) {
  return Belt_Option.getWithDefault(Js_dict.get(dict, key), $$default);
}

function getJsonObjFromDict(dict, key, $$default) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeObject), $$default);
}

function getDecodedStringFromJson(json, callbackFunc, defaultValue) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(json), callbackFunc), Js_json.decodeString), defaultValue);
}

function getRequiredString(dict, key, $$default, logger) {
  var optionalStr = getOptionString(dict, key);
  if (optionalStr !== undefined) {
    if (optionalStr === "") {
      ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* REQUIRED_PARAMETER */41, key, logger, undefined);
    }
    return optionalStr;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* REQUIRED_PARAMETER */41, key, logger, undefined);
    return Belt_Option.getWithDefault(optionalStr, $$default);
  }
}

function getWarningString(dict, key, $$default, logger) {
  var val = Js_dict.get(dict, key);
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Js_json.decodeString(Caml_option.valFromOption(val));
  if (val$1 !== undefined) {
    return val$1;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* TYPE_STRING_ERROR */46, key, logger, undefined);
    return $$default;
  }
}

function getDictFromObj(dict, key) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeObject), {});
}

function getJsonObjectFromDict(dict, key) {
  return Belt_Option.getWithDefault(Js_dict.get(dict, key), {});
}

function getOptionBool(dict, key) {
  return Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeBoolean);
}

function getDictFromJson(json) {
  return Belt_Option.getWithDefault(Js_json.decodeObject(json), {});
}

function getBool(dict, key, $$default) {
  return Belt_Option.getWithDefault(getOptionBool(dict, key), $$default);
}

function getBoolWithWarning(dict, key, $$default, logger) {
  var val = Js_dict.get(dict, key);
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Js_json.decodeBoolean(Caml_option.valFromOption(val));
  if (val$1 !== undefined) {
    return val$1;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* TYPE_BOOL_ERROR */44, key, logger, undefined);
    return $$default;
  }
}

function getNumberWithWarning(dict, key, logger, $$default) {
  var val = Js_dict.get(dict, key);
  if (val === undefined) {
    return $$default;
  }
  var val$1 = Js_json.decodeNumber(Caml_option.valFromOption(val));
  if (val$1 !== undefined) {
    return val$1 | 0;
  } else {
    ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* TYPE_INT_ERROR */45, key, logger, undefined);
    return $$default;
  }
}

function getOptionalArrayFromDict(dict, key) {
  return Belt_Option.flatMap(Js_dict.get(dict, key), Js_json.decodeArray);
}

function getArray(dict, key) {
  return Belt_Option.getWithDefault(getOptionalArrayFromDict(dict, key), []);
}

function getStrArray(dict, key) {
  return Belt_Array.map(Belt_Option.getWithDefault(getOptionalArrayFromDict(dict, key), []), (function (json) {
                return Belt_Option.getWithDefault(Js_json.decodeString(json), "");
              }));
}

function getOptionalStrArray(dict, key) {
  var val = getOptionalArrayFromDict(dict, key);
  if (val !== undefined && val.length !== 0) {
    return Belt_Array.map(val, (function (json) {
                  return Belt_Option.getWithDefault(Js_json.decodeString(json), "");
                }));
  }
  
}

function getBoolValue(val) {
  return Belt_Option.getWithDefault(val, false);
}

function toKebabCase(str) {
  return str.split("").map(function (item, i) {
                if (item.toUpperCase() === item) {
                  return (
                          i !== 0 ? "-" : ""
                        ) + item.toLowerCase();
                } else {
                  return item;
                }
              }).join("");
}

function handleMessage(fun, _errorMessage) {
  var handle = function (ev) {
    try {
      return Curry._1(fun, ev);
    }
    catch (_err){
      return ;
    }
  };
  window.addEventListener("message", handle);
  return (function (param) {
            window.removeEventListener("message", handle);
            
          });
}

function submitPaymentData(callback) {
  React.useEffect((function () {
          return handleMessage(callback, "");
        }), [callback]);
  
}

function useWindowSize(param) {
  var match = React.useState(function () {
        return [
                0,
                0
              ];
      });
  var setSize = match[1];
  React.useLayoutEffect((function () {
          var updateSize = function (param) {
            return Curry._1(setSize, (function (param) {
                          return [
                                  window.innerWidth,
                                  window.innerHeight
                                ];
                        }));
          };
          window.addEventListener("resize", updateSize);
          Curry._1(setSize, (function (param) {
                  return [
                          window.innerWidth,
                          window.innerHeight
                        ];
                }));
          return (function (param) {
                    window.removeEventListener("resize", updateSize);
                    
                  });
        }), []);
  return match[0];
}

function mergeJsons(json1, json2) {
  var obj1 = getDictFromJson(json1);
  var obj2 = getDictFromJson(json2);
  var merge = function (obj1, obj2) {
    if (Caml_obj.caml_notequal(obj1, obj2)) {
      Object.keys(obj2).map(function (key) {
            var overrideProp = getJsonObjectFromDict(obj2, key);
            var defaultProp = getJsonObjectFromDict(obj1, key);
            if (Object.keys(getDictFromJson(defaultProp)).length === 0) {
              obj1[key] = overrideProp;
              return ;
            } else if (Belt_Option.isSome(Js_json.decodeObject(overrideProp)) && Belt_Option.isSome(Js_json.decodeObject(defaultProp))) {
              return merge(getDictFromJson(defaultProp), getDictFromJson(overrideProp));
            } else if (overrideProp !== defaultProp) {
              obj1[key] = overrideProp;
              return ;
            } else {
              return ;
            }
          });
    }
    
  };
  merge(obj1, obj2);
  return obj1;
}

function postFailedSubmitResponse(errortype, message) {
  var errorDict = Js_dict.fromArray([
        [
          "type",
          errortype
        ],
        [
          "message",
          message
        ]
      ]);
  return handlePostMessage(undefined, [
              [
                "submitSuccessful",
                false
              ],
              [
                "error",
                errorDict
              ]
            ]);
}

function postSubmitResponse(jsonData, url) {
  return handlePostMessage(undefined, [
              [
                "submitSuccessful",
                true
              ],
              [
                "data",
                jsonData
              ],
              [
                "url",
                url
              ]
            ]);
}

function toCamelCase(str) {
  if (str.includes(":")) {
    return str;
  } else {
    return str.toLowerCase().replace(/([-_][a-z])/g, (function (letter, param, param$1) {
                    return letter.toUpperCase();
                  })).replace(/[^a-zA-Z]/g, "");
  }
}

function toSnakeCase(str) {
  return str.replace(/[A-Z]/g, (function (letter, param, param$1) {
                return "_" + letter.toLowerCase();
              }));
}

function transformKeys(json, to) {
  var toCase;
  switch (to) {
    case /* CamelCase */0 :
        toCase = toCamelCase;
        break;
    case /* SnakeCase */1 :
        toCase = toSnakeCase;
        break;
    case /* KebabCase */2 :
        toCase = toKebabCase;
        break;
    
  }
  var dict = getDictFromJson(json);
  return Js_dict.fromArray(Js_dict.entries(dict).map(function (param) {
                  var value = param[1];
                  var key = param[0];
                  var obj = Js_json.classify(value);
                  if (typeof obj === "number") {
                    return [
                            Curry._1(toCase, key),
                            value
                          ];
                  }
                  switch (obj.TAG | 0) {
                    case /* JSONString */0 :
                        var str = obj._0;
                        var val = str === "Final" ? "FINAL" : (
                            str === "example" || str === "Adyen" ? "adyen" : (
                                str === "exampleGatewayMerchantId" ? "Sampras123ECOM" : str
                              )
                          );
                        return [
                                Curry._1(toCase, key),
                                val
                              ];
                    case /* JSONNumber */1 :
                        return [
                                Curry._1(toCase, key),
                                String(obj._0)
                              ];
                    case /* JSONObject */2 :
                        return [
                                Curry._1(toCase, key),
                                transformKeys(obj._0, to)
                              ];
                    case /* JSONArray */3 :
                        return [
                                Curry._1(toCase, key),
                                obj._0.map(function (item) {
                                      if (Belt_Option.isSome(Js_json.decodeObject(item))) {
                                        return transformKeys(item, to);
                                      } else {
                                        return item;
                                      }
                                    })
                              ];
                    
                  }
                }));
}

function getClientCountry(clientTimeZone) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(Country$OrcaPaymentPage.country.find(function (item) {
                      return Belt_Option.isSome(Caml_option.undefined_to_opt(item.timeZones.find(function (i) {
                                          return i === clientTimeZone;
                                        })));
                    })), Country$OrcaPaymentPage.defaultTimeZone);
}

function removeDuplicate(arr) {
  return arr.filter(function (item, i) {
              return arr.indexOf(item) === i;
            });
}

function checkEmailValid(email, fn) {
  var _match = email.value.match(/^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if (_match !== null) {
    return fn(function (prev) {
                return {
                        value: prev.value,
                        isValid: true,
                        errorString: prev.errorString
                      };
              });
  } else if (email.value.length > 0) {
    return fn(function (prev) {
                return {
                        value: prev.value,
                        isValid: false,
                        errorString: prev.errorString
                      };
              });
  } else {
    return fn(function (prev) {
                return {
                        value: prev.value,
                        isValid: undefined,
                        errorString: prev.errorString
                      };
              });
  }
}

function validatePhoneNumber(countryCode, number) {
  var phoneNumberDict = Belt_Option.getWithDefault(Js_json.decodeObject(phoneNumberJson), {});
  var countriesArr = Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(phoneNumberDict, "countries"), Js_json.decodeArray), []), Js_json.decodeObject);
  var filteredArr = countriesArr.filter(function (countryObj) {
        return Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(countryObj, "phone_number_code"), Js_json.decodeString), "") === countryCode;
      });
  if (filteredArr.length <= 0) {
    return false;
  }
  var obj = Caml_array.get(filteredArr, 0);
  var regex = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(obj, "validation_regex"), Js_json.decodeString), "");
  return new RegExp(regex).test(number);
}

function sortBasedOnPriority(sortArr, priorityArr) {
  var finalPriorityArr = priorityArr.filter(function (val) {
        return sortArr.includes(val);
      });
  sortArr.map(function (item) {
        if (finalPriorityArr.includes(item)) {
          return ;
        } else {
          finalPriorityArr.push(item);
          return ;
        }
      });
  return finalPriorityArr;
}

function isAllValid(card, cvc, expiry, zip, paymentMode) {
  if (Belt_Option.getWithDefault(card, false) && Belt_Option.getWithDefault(cvc, false) && Belt_Option.getWithDefault(expiry, false)) {
    if (paymentMode === "payment") {
      return true;
    } else {
      return zip;
    }
  } else {
    return false;
  }
}

function getCountryPostal(countryCode, postalCodes) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(postalCodes.find(function (item) {
                      return item.iso === countryCode;
                    })), PostalCodeType$OrcaPaymentPage.defaultPostalCode);
}

function getCountryNames(list) {
  return list.reduce((function (arr, item) {
                arr.push(item.countryName);
                return arr;
              }), []);
}

function getBankNames(list, allBanks) {
  return list.reduce((function (arr, item) {
                if (allBanks.includes(item.hyperSwitch)) {
                  arr.push(item.displayName);
                }
                return arr;
              }), []);
}

function getBankKeys(str, banks, $$default) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(banks.find(function (item) {
                      return item.displayName === str;
                    })), $$default).hyperSwitch;
}

function constructClass(classname, dict) {
  var puseduoArr = [];
  var modifiedArr = [];
  Js_dict.entries(dict).map(function (entry) {
        var value = entry[1];
        var key = entry[0];
        var $$class;
        if (!key.startsWith(":") && !key.startsWith(".")) {
          var str = Js_json.decodeString(value);
          $$class = str !== undefined ? toKebabCase(key) + ":" + str : "";
        } else if (key.startsWith(":")) {
          var obj = Js_json.decodeObject(value);
          if (obj !== undefined) {
            var style = Js_dict.entries(Caml_option.valFromOption(obj)).map(function (entry) {
                  var str = Js_json.decodeString(entry[1]);
                  if (str !== undefined) {
                    return toKebabCase(entry[0]) + ":" + str;
                  } else {
                    return "";
                  }
                });
            $$class = "." + classname + key + " {" + style.join(";") + "}";
          } else {
            $$class = "";
          }
        } else if (key.startsWith(".")) {
          var obj$1 = Js_json.decodeObject(value);
          if (obj$1 !== undefined) {
            var style$1 = Js_dict.entries(Caml_option.valFromOption(obj$1)).map(function (entry) {
                  var str = Js_json.decodeString(entry[1]);
                  if (str !== undefined) {
                    return toKebabCase(entry[0]) + ":" + str;
                  } else {
                    return "";
                  }
                });
            $$class = key + " {" + style$1.join(";") + "} ";
          } else {
            $$class = "";
          }
        } else {
          $$class = "";
        }
        if (!key.startsWith(":") && !key.startsWith(".")) {
          modifiedArr.push($$class);
          return ;
        } else if (key.startsWith(":") || key.startsWith(".")) {
          puseduoArr.push($$class);
          return ;
        } else {
          return ;
        }
      });
  if (classname.length === 0) {
    return modifiedArr.join(";") + " " + puseduoArr.join(" ");
  } else {
    return "." + classname + " {" + modifiedArr.join(";") + "} " + puseduoArr.join(" ");
  }
}

function generateStyleSheet(classname, dict, id) {
  var createStyle = function (param) {
    var style = document.createElement("style");
    style.type = "text/css";
    style.id = id;
    style.appendChild(document.createTextNode(constructClass(classname, dict)));
    document.body.appendChild(style);
    
  };
  var val = window.document.getElementById(id);
  if (val == null) {
    return createStyle(undefined);
  } else {
    val.remove();
    return createStyle(undefined);
  }
}

function openUrl(url) {
  return handlePostMessage(undefined, [[
                "openurl",
                url
              ]]);
}

function getArrofJsonString(arr) {
  return arr.map(function (item) {
              return item;
            });
}

function getPaymentDetails(arr) {
  var finalArr = [];
  arr.map(function (item) {
        var optionalVal = PaymentDetails$OrcaPaymentPage.details.find(function (i) {
              return i.type_ === item;
            });
        if (optionalVal !== undefined) {
          finalArr.push(optionalVal);
          return ;
        }
        
      });
  return finalArr;
}

function getOptionalArr(arr) {
  if (arr !== undefined) {
    return arr;
  } else {
    return [];
  }
}

function checkPriorityList(paymentMethodOrder) {
  if (Belt_Option.getWithDefault(Belt_Array.get(paymentMethodOrder !== undefined ? paymentMethodOrder : [], 0), "") === "card") {
    return true;
  } else {
    return Belt_Option.isNone(paymentMethodOrder);
  }
}

function addSize(str, value, unit) {
  var getUnit = function (unit) {
    switch (unit) {
      case /* Pixel */0 :
          return "px";
      case /* Rem */1 :
          return "rem";
      case /* Em */2 :
          return "em";
      
    }
  };
  var unitInString = getUnit(unit);
  if (!str.endsWith(unitInString)) {
    return str;
  }
  var arr = str.split("");
  var val = Belt_Option.getWithDefault(Belt_Float.fromString(arr.slice(0, arr.length - unitInString.length | 0).join("")), 0.0);
  return String(val + value) + unitInString;
}

function toInt(val) {
  return Belt_Option.getWithDefault(Belt_Int.fromString(val), 0);
}

function validateRountingNumber(str) {
  if (str.length !== 9) {
    return false;
  }
  var weights = [
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1
  ];
  var sum = str.split("").map(function (item, i) {
          return Math.imul(Belt_Option.getWithDefault(Belt_Int.fromString(item), 0), Caml_array.get(weights, i));
        }).reduce((function (acc, val) {
          return acc + val | 0;
        }), 0);
  return sum % 10 === 0;
}

function handlePostMessageEvents(complete, empty, paymentType, loggerState) {
  if (complete && paymentType !== "") {
    Curry._7(loggerState.setLogInfo, "Payment Data Filled", /* PAYMENT_DATA_FILLED */4, undefined, undefined, undefined, paymentType, undefined);
  }
  return handlePostMessage(undefined, [
              [
                "elementType",
                "payment"
              ],
              [
                "complete",
                complete
              ],
              [
                "empty",
                empty
              ],
              [
                "value",
                Js_dict.fromArray([[
                        "type",
                        paymentType
                      ]])
              ]
            ]);
}

function onlyDigits(str) {
  return str.replace(/\D/g, "");
}

function getCountryCode(country) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(Country$OrcaPaymentPage.country.find(function (item) {
                      return item.countryName === country;
                    })), Country$OrcaPaymentPage.defaultTimeZone);
}

function getStateNames(list, country) {
  var options = Belt_Option.getWithDefault(getOptionalArrayFromDict(getDictFromJson(list), getCountryCode(country.value).isoAlpha2), []);
  return options.reduce((function (arr, item) {
                arr.push(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(getDictFromJson(item), "name"), Js_json.decodeString), ""));
                return arr;
              }), []);
}

function isAddressComplete(line1, city, postalCode, country, state) {
  if (line1.value !== "" && city.value !== "" && postalCode.value !== "" && country.value !== "") {
    return state.value !== "";
  } else {
    return false;
  }
}

function deepCopyDict(dict) {
  var emptyDict = {};
  Js_dict.entries(dict).map(function (item) {
        emptyDict[item[0]] = item[1];
        
      });
  return emptyDict;
}

function snakeToTitleCase(str) {
  var words = str.split("_");
  return words.map(function (item) {
                return item.charAt(0).toUpperCase() + item.slice(1);
              }).join(" ");
}

function logInfo(log) {
  if (GlobalVars$OrcaPaymentPage.isProd) {
    return ;
  } else {
    return log;
  }
}

function formatIBAN(iban) {
  var formatted = iban.replace(/[^a-zA-Z0-9]/g, "");
  var countryCode = formatted.substring(0, 2).toUpperCase();
  var codeLastTwo = formatted.substring(2, 4);
  var remaining = formatted.substr(4);
  var matches = remaining.match(/(.{1,4})/g);
  var chunks = matches !== null ? matches : [];
  return (countryCode + codeLastTwo + " " + chunks.join(" ")).trim();
}

function getDictIsSome(dict, key) {
  return Belt_Option.isSome(Js_dict.get(dict, key));
}

function delay(timeOut) {
  return new Promise((function (resolve, _reject) {
                setTimeout((function (param) {
                        return resolve({});
                      }), timeOut);
                
              }));
}

export {
  phoneNumberJson ,
  handlePostMessage ,
  handleOnFocusPostMessage ,
  handleOnBlurPostMessage ,
  handleOnClickPostMessage ,
  handleOnConfirmPostMessage ,
  getOptionString ,
  getString ,
  getInt ,
  getJsonBoolValue ,
  getJsonStringFromDict ,
  getJsonArrayFromDict ,
  getJsonFromDict ,
  getJsonObjFromDict ,
  getDecodedStringFromJson ,
  getRequiredString ,
  getWarningString ,
  getDictFromObj ,
  getJsonObjectFromDict ,
  getOptionBool ,
  getDictFromJson ,
  getBool ,
  getBoolWithWarning ,
  getNumberWithWarning ,
  getOptionalArrayFromDict ,
  getArray ,
  getStrArray ,
  getOptionalStrArray ,
  getBoolValue ,
  toKebabCase ,
  handleMessage ,
  submitPaymentData ,
  useWindowSize ,
  mergeJsons ,
  postFailedSubmitResponse ,
  postSubmitResponse ,
  toCamelCase ,
  toSnakeCase ,
  transformKeys ,
  getClientCountry ,
  removeDuplicate ,
  checkEmailValid ,
  validatePhoneNumber ,
  sortBasedOnPriority ,
  isAllValid ,
  getCountryPostal ,
  getCountryNames ,
  getBankNames ,
  getBankKeys ,
  constructClass ,
  generateStyleSheet ,
  openUrl ,
  getArrofJsonString ,
  getPaymentDetails ,
  getOptionalArr ,
  checkPriorityList ,
  addSize ,
  toInt ,
  validateRountingNumber ,
  handlePostMessageEvents ,
  onlyDigits ,
  getCountryCode ,
  getStateNames ,
  isAddressComplete ,
  deepCopyDict ,
  snakeToTitleCase ,
  logInfo ,
  formatIBAN ,
  getDictIsSome ,
  delay ,
  
}
/* phoneNumberJson Not a pure module */
