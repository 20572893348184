// Generated by ReScript, PLEASE EDIT WITH CARE


function RenderIf(Props) {
  var children = Props.children;
  var condition = Props.condition;
  if (condition) {
    return children;
  } else {
    return null;
  }
}

var make = RenderIf;

export {
  make ,
  
}
/* No side effect */
