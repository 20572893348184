// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types$OrcaPaymentPage from "./Types.bs.js";
import * as Utils$OrcaPaymentPage from "../Utilities/Utils.bs.js";
import * as Window$OrcaPaymentPage from "../Window.bs.js";
import * as Elements$OrcaPaymentPage from "./Elements.bs.js";
import * as ErrorUtils$OrcaPaymentPage from "../Utilities/ErrorUtils.bs.js";
import * as OrcaLogger$OrcaPaymentPage from "../orca-log-catcher/OrcaLogger.bs.js";
import * as ApiEndpoint$OrcaPaymentPage from "../Utilities/ApiEndpoint.bs.js";
import * as LoggerUtils$OrcaPaymentPage from "../Utilities/LoggerUtils.bs.js";
import * as EventListenerManager$OrcaPaymentPage from "../Utilities/EventListenerManager.bs.js";

function checkAndAppend(selector, child) {
  if (Caml_option.nullable_to_opt(document.querySelector(selector)) === undefined) {
    document.body.appendChild(child);
    return ;
  }
  
}

function preloadFile(type_, fileOpt, hrefOpt, param) {
  var file = fileOpt !== undefined ? fileOpt : "";
  var href = hrefOpt !== undefined ? hrefOpt : "https://checkout.hyperswitch.io" + publicPath + "/" + file;
  var link = document.createElement("link");
  link.href = href;
  link.as = type_;
  link.rel = "preload";
  link.crossorigin = "anonymous";
  return checkAndAppend("link[href=\"" + href + "\"]", link);
}

function preloader(param) {
  preloadFile("document", "", undefined, undefined);
  preloadFile("script", "app.js", undefined, undefined);
  preloadFile("style", "app.css", undefined, undefined);
  preloadFile("image", "icons/orca.svg", undefined, undefined);
  preloadFile("style", undefined, "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700;800&display=swap", undefined);
  preloadFile("style", undefined, "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&family=Qwitcher+Grypen:wght@400;700&display=swap", undefined);
  preloadFile("script", undefined, "https://js.braintreegateway.com/web/3.88.4/js/paypal-checkout.min.js", undefined);
  return preloadFile("script", undefined, "https://js.braintreegateway.com/web/3.88.4/js/client.min.js", undefined);
}

function make(publishableKey, _options, analyticsInfo) {
  try {
    preloader(undefined);
    var analyticsInfoDict = Belt_Option.getWithDefault(Belt_Option.flatMap(analyticsInfo, Js_json.decodeObject), {});
    var sessionID = Utils$OrcaPaymentPage.getString(analyticsInfoDict, "sessionID", "");
    var sdkTimestamp = Utils$OrcaPaymentPage.getString(analyticsInfoDict, "timeStamp", String(Date.now()));
    var logger = OrcaLogger$OrcaPaymentPage.make("loader", sessionID, /* Loader */0, undefined, publishableKey, undefined);
    Curry._1(logger.setMerchantId, publishableKey);
    Curry._1(logger.setSessionId, sessionID);
    Curry._7(logger.setLogInfo, "orca-sdk initiated", /* APP_INITIATED */27, sdkTimestamp, undefined, undefined, undefined, undefined);
    var hyperMethod = window.HyperMethod;
    if (hyperMethod == null) {
      var loaderTimestamp = String(Date.now());
      Curry._7(logger.setLogInfo, "loadStripe has been called", /* LOADER_CALLED */29, loaderTimestamp, undefined, undefined, undefined, undefined);
      if (publishableKey === "" || !(publishableKey.startsWith("pk_snd_") || publishableKey.startsWith("pk_prd_"))) {
        ErrorUtils$OrcaPaymentPage.manageErrorWarning(/* INVALID_PK */39, undefined, logger, undefined);
      }
      if (document.querySelectorAll("script[src=\"https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js\"]").length === 0) {
        var script = document.createElement("script");
        script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
        script.onerror = (function (err) {
            console.log("ERROR DURING LOADING APPLE PAY", err);
            
          });
        document.body.appendChild(script);
      }
      var iframeRef = {
        contents: []
      };
      var handleApplePayMessages = {
        contents: (function (param) {
            
          })
      };
      var clientSecret = {
        contents: ""
      };
      var setIframeRef = function (ref) {
        iframeRef.contents.push(ref);
        
      };
      var applePaySessionRef = {
        contents: null
      };
      var retrievePaymentIntentFn = function (clientSecret) {
        var headers = {
          Accept: "application/json",
          "api-key": publishableKey
        };
        var paymentIntentID = Caml_array.get(clientSecret.split("_secret_"), 0);
        var retrievePaymentUrl = ApiEndpoint$OrcaPaymentPage.endpoint + "/payments/" + paymentIntentID + "?client_secret=" + clientSecret;
        LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL_INIT */11, undefined, undefined, "request", retrievePaymentUrl, undefined, undefined, logger, /* INFO */1, /* API */0, undefined);
        return fetch(retrievePaymentUrl, Fetch.RequestInit.make(/* Get */0, Caml_option.some(headers), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
                      var statusCode = String(resp.status);
                      if (statusCode.charAt(0) !== "2") {
                        resp.json().then(function (data) {
                              LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL */12, statusCode, Caml_option.some(data), "err", retrievePaymentUrl, undefined, undefined, logger, /* ERROR */2, /* API */0, undefined);
                              return Promise.resolve(undefined);
                            });
                      } else {
                        LoggerUtils$OrcaPaymentPage.logApi(/* RETRIEVE_CALL */12, statusCode, undefined, "response", retrievePaymentUrl, undefined, undefined, logger, /* INFO */1, /* API */0, undefined);
                      }
                      return resp.json();
                    }).then(function (data) {
                    return Promise.resolve(Js_dict.fromArray([[
                                      "paymentIntent",
                                      data
                                    ]]));
                  });
      };
      var confirmPayment = function (payload) {
        var confirmParams = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_json.decodeObject(payload), (function (x) {
                    return Js_dict.get(x, "confirmParams");
                  })), {});
        var redirect = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(payload), (function (x) {
                        return Js_dict.get(x, "redirect");
                      })), Js_json.decodeString), "if_required");
        var url = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(confirmParams), (function (x) {
                        return Js_dict.get(x, "return_url");
                      })), Js_json.decodeString), "");
        return new Promise((function (resolve, param) {
                      iframeRef.contents.forEach(function (ifR) {
                            return Window$OrcaPaymentPage.iframePostMessage(ifR, Js_dict.fromArray([
                                            [
                                              "doSubmit",
                                              true
                                            ],
                                            [
                                              "clientSecret",
                                              clientSecret.contents
                                            ],
                                            [
                                              "confirmParams",
                                              Js_dict.fromArray([
                                                    [
                                                      "return_url",
                                                      url
                                                    ],
                                                    [
                                                      "publishableKey",
                                                      publishableKey
                                                    ]
                                                  ])
                                            ]
                                          ]));
                          });
                      var handleMessage = function ($$event) {
                        var json = $$event.data;
                        var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                        var val = Js_dict.get(dict, "submitSuccessful");
                        if (val === undefined) {
                          return ;
                        }
                        var val$1 = Caml_option.valFromOption(val);
                        LoggerUtils$OrcaPaymentPage.logApi(/* CONFIRM_PAYMENT */36, undefined, undefined, "method", undefined, "confirmPayment", Caml_option.some(val$1), logger, undefined, undefined, undefined);
                        var data = Belt_Option.getWithDefault(Js_dict.get(dict, "data"), {});
                        var returnUrl = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(dict, "url"), Js_json.decodeString), url);
                        if (Belt_Option.getWithDefault(Js_json.decodeBoolean(val$1), false) && redirect === "always") {
                          window.location.replace(returnUrl);
                          return ;
                        } else if (Belt_Option.getWithDefault(Js_json.decodeBoolean(val$1), false)) {
                          return resolve(data);
                        } else {
                          return resolve(json);
                        }
                      };
                      return EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleMessage, "onSubmit");
                    }));
      };
      var elements = function (elementsOptions) {
        Curry._7(logger.setLogInfo, "stripe.elements called", /* STRIPE_ELEMENTS_CALLED */30, undefined, undefined, undefined, undefined, undefined);
        var clientSecretId = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(elementsOptions), (function (x) {
                        return Js_dict.get(x, "clientSecret");
                      })), Js_json.decodeString), "");
        clientSecret.contents = clientSecretId;
        Curry._1(logger.setClientSecret, clientSecretId);
        return Elements$OrcaPaymentPage.make(elementsOptions, setIframeRef, clientSecretId, sessionID, publishableKey, applePaySessionRef, handleApplePayMessages, logger);
      };
      var confirmCardPaymentFn = function (clientSecretId, data, _options) {
        var decodedData = Belt_Option.getWithDefault(Belt_Option.flatMap(data, Js_json.decodeObject), {});
        return new Promise((function (resolve, param) {
                      iframeRef.contents.map(function (iframe) {
                            Window$OrcaPaymentPage.iframePostMessage(iframe, Js_dict.fromArray([
                                      [
                                        "doSubmit",
                                        true
                                      ],
                                      [
                                        "clientSecret",
                                        clientSecretId
                                      ],
                                      [
                                        "confirmParams",
                                        Js_dict.fromArray([[
                                                "publishableKey",
                                                publishableKey
                                              ]])
                                      ]
                                    ]));
                            var handleMessage = function ($$event) {
                              var json = $$event.data;
                              var dict = Utils$OrcaPaymentPage.getDictFromJson(json);
                              var val = Js_dict.get(dict, "submitSuccessful");
                              if (val === undefined) {
                                return resolve(json);
                              }
                              var val$1 = Caml_option.valFromOption(val);
                              LoggerUtils$OrcaPaymentPage.logApi(/* CONFIRM_CARD_PAYMENT */37, undefined, undefined, "method", undefined, "confirmCardPayment", Caml_option.some(val$1), logger, undefined, undefined, undefined);
                              var url = Utils$OrcaPaymentPage.getString(decodedData, "return_url", "/");
                              if (Belt_Option.getWithDefault(Js_json.decodeBoolean(val$1), false) && url !== "/") {
                                window.location.replace(url);
                                return ;
                              } else {
                                return resolve(json);
                              }
                            };
                            return function (param) {
                              return EventListenerManager$OrcaPaymentPage.addSmartEventListener("message", handleMessage, param);
                            };
                          });
                      
                    }));
      };
      var addAmountToDict = function (dict, currency) {
        if (Belt_Option.isNone(Js_dict.get(dict, "amount"))) {
          console.error("Amount is not specified, please input an amount");
        }
        var amount = Belt_Option.getWithDefault(Js_dict.get(dict, "amount"), 0.0);
        dict["amount"] = Js_dict.fromArray([
              [
                "currency",
                currency
              ],
              [
                "value",
                amount
              ]
            ]);
        return Caml_option.some(dict);
      };
      var paymentRequest = function (options) {
        var optionsDict = Utils$OrcaPaymentPage.getDictFromJson(options);
        var currency = Utils$OrcaPaymentPage.getJsonStringFromDict(optionsDict, "currency", "");
        var optionsTotal = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(optionsDict, "total"), Js_json.decodeObject), (function (x) {
                    return addAmountToDict(x, currency);
                  })), {});
        var displayItems = Utils$OrcaPaymentPage.getJsonArrayFromDict(optionsDict, "displayItems", []);
        var requestPayerName = Utils$OrcaPaymentPage.getJsonStringFromDict(optionsDict, "requestPayerName", "");
        var requestPayerEmail = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestPayerEmail", false);
        var requestPayerPhone = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestPayerPhone", false);
        var requestShipping = Utils$OrcaPaymentPage.getJsonBoolValue(optionsDict, "requestShipping", false);
        var shippingOptions = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Js_dict.get(optionsDict, "shippingOptions"), Js_json.decodeObject), (function (x) {
                    return addAmountToDict(x, currency);
                  })), {});
        var applePayPaymentMethodData = Js_dict.fromArray([
              [
                "supportedMethods",
                "https://apple.com/apple-pay"
              ],
              [
                "data",
                Js_dict.fromArray([[
                        "version",
                        12.00
                      ]])
              ]
            ]);
        var methodData = [applePayPaymentMethodData];
        var details = Js_dict.fromArray([
              [
                "id",
                publishableKey
              ],
              [
                "displayItems",
                displayItems
              ],
              [
                "total",
                optionsTotal
              ],
              [
                "shippingOptions",
                shippingOptions
              ]
            ]);
        var optionsForPaymentRequest = Js_dict.fromArray([
              [
                "requestPayerName",
                requestPayerName
              ],
              [
                "requestPayerEmail",
                requestPayerEmail
              ],
              [
                "requestPayerPhone",
                requestPayerPhone
              ],
              [
                "requestShipping",
                requestShipping
              ],
              [
                "shippingType",
                "shipping"
              ]
            ]);
        return methodData.PaymentRequest(details, optionsForPaymentRequest);
      };
      var returnObject = {
        confirmPayment: confirmPayment,
        elements: elements,
        confirmCardPayment: confirmCardPaymentFn,
        retrievePaymentIntent: retrievePaymentIntentFn,
        widgets: elements,
        paymentRequest: paymentRequest
      };
      window.HyperMethod = returnObject;
      return returnObject;
    }
    Curry._7(logger.setLogInfo, "orca-sdk initiated", /* APP_REINITIATED */28, sdkTimestamp, undefined, undefined, undefined, undefined);
    return hyperMethod;
  }
  catch (_e){
    return Types$OrcaPaymentPage.defaultStripeInstance;
  }
}

export {
  checkAndAppend ,
  preloadFile ,
  preloader ,
  make ,
  
}
/* Types-OrcaPaymentPage Not a pure module */
