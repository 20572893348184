// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$OrcaPaymentPage from "./Icon.bs.js";
import * as Utils$OrcaPaymentPage from "./Utilities/Utils.bs.js";
import * as CardPattern$OrcaPaymentPage from "./CardPattern.bs.js";
import * as PaymentType$OrcaPaymentPage from "./Types/PaymentType.bs.js";

function toInt(val) {
  return Belt_Option.getWithDefault(Belt_Int.fromString(val), 0);
}

function toString(val) {
  return String(val);
}

function getQueryParamsDictforKey(searchParams, keyName) {
  var dict = {};
  searchParams.split("&").forEach(function (paramStr) {
        var keyValArr = paramStr.split("=");
        var key = Belt_Option.getWithDefault(Belt_Array.get(keyValArr, 0), "");
        var value = keyValArr.length > 0 ? Belt_Option.getWithDefault(Belt_Array.get(keyValArr, 1), "") : "";
        dict[key] = value;
        
      });
  return Belt_Option.getWithDefault(Js_dict.get(dict, keyName), "");
}

function cardType(val) {
  switch (val) {
    case "AmericanExpress" :
        return /* AMEX */2;
    case "BAJAJ" :
        return /* BAJAJ */6;
    case "CartesBancaires" :
        return /* CARTESBANCAIRES */10;
    case "DinersClub" :
        return /* DINERSCLUB */4;
    case "Discover" :
        return /* DISCOVER */5;
    case "Interac" :
        return /* INTERAC */12;
    case "JCB" :
        return /* JCB */9;
    case "Maestro" :
        return /* MAESTRO */3;
    case "Mastercard" :
        return /* MASTERCARD */1;
    case "RuPay" :
        return /* RUPAY */8;
    case "SODEXO" :
        return /* SODEXO */7;
    case "UnionPay" :
        return /* UNIONPAY */11;
    case "Visa" :
        return /* VISA */0;
    default:
      return /* NOTFOUND */13;
  }
}

function getobjFromCardPattern(cardBrand) {
  return Belt_Option.getWithDefault(Belt_Array.get(CardPattern$OrcaPaymentPage.cardPatterns.filter(function (item) {
                      return cardBrand === item.issuer;
                    }), 0), CardPattern$OrcaPaymentPage.defaultCardPattern);
}

function clearSpaces(value) {
  return value.replace(/\D+/g, "");
}

function slice(val, from, to_) {
  return val.slice(from, to_);
}

function getStrFromIndex(arr, index) {
  return Belt_Option.getWithDefault(Belt_Array.get(arr, index), "");
}

function formatCVCNumber(val, cardType) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(cardType);
  return clearValue.slice(0, obj.maxCVCLenth);
}

function getCurrentMonthAndYear(dateTimeIsoString) {
  var tempTimeDateString = dateTimeIsoString.replace("Z", "");
  var tempTimeDate = tempTimeDateString.split("T");
  var date = Caml_array.get(tempTimeDate, 0);
  var dateComponents = date.split("-");
  var currentMonth = Belt_Option.getWithDefault(Belt_Array.get(dateComponents, 1), "");
  var currentYear = Belt_Option.getWithDefault(Belt_Array.get(dateComponents, 0), "");
  return [
          Belt_Option.getWithDefault(Belt_Int.fromString(currentMonth), 0),
          Belt_Option.getWithDefault(Belt_Int.fromString(currentYear), 0)
        ];
}

function formatCardNumber(val, cardType) {
  var clearValue = clearSpaces(val);
  var tmp;
  var exit = 0;
  switch (cardType) {
    case /* AMEX */2 :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 10) + " " + clearValue.slice(10, 15);
        break;
    case /* DINERSCLUB */4 :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 10) + " " + clearValue.slice(10, 14);
        break;
    case /* VISA */0 :
    case /* MASTERCARD */1 :
    case /* DISCOVER */5 :
    case /* SODEXO */7 :
    case /* RUPAY */8 :
        exit = 2;
        break;
    case /* MAESTRO */3 :
    case /* BAJAJ */6 :
    case /* JCB */9 :
    case /* CARTESBANCAIRES */10 :
    case /* UNIONPAY */11 :
    case /* INTERAC */12 :
    case /* NOTFOUND */13 :
        exit = 1;
        break;
    
  }
  switch (exit) {
    case 1 :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 8) + " " + clearValue.slice(8, 12) + " " + clearValue.slice(12, 19);
        break;
    case 2 :
        tmp = clearValue.slice(0, 4) + " " + clearValue.slice(4, 8) + " " + clearValue.slice(8, 12) + " " + clearValue.slice(12, 16) + " " + clearValue.slice(16, 19);
        break;
    
  }
  return tmp.trim();
}

function splitExpiryDates(val) {
  var split = val.split("/");
  var value = split.map(function (item) {
        return item.trim();
      });
  var month = Belt_Option.getWithDefault(Belt_Array.get(value, 0), "");
  var year = Belt_Option.getWithDefault(Belt_Array.get(value, 1), "");
  return [
          month,
          year
        ];
}

function getExpiryDates(val) {
  var date = new Date().toISOString();
  var match = splitExpiryDates(val);
  var match$1 = getCurrentMonthAndYear(date);
  var prefix = String(match$1[1]).slice(0, 2);
  return [
          match[0],
          prefix + match[1]
        ];
}

function formatExpiryToTwoDigit(expiry) {
  if (expiry.length === 2) {
    return expiry;
  } else {
    return expiry.slice(2, 4);
  }
}

function isExipryComplete(val) {
  var match = splitExpiryDates(val);
  if (match[0].length === 2) {
    return match[1].length === 2;
  } else {
    return false;
  }
}

function formatCardExpiryNumber(val) {
  var clearValue = clearSpaces(val);
  var expiryVal = Belt_Option.getWithDefault(Belt_Int.fromString(clearValue), 0);
  var formatted;
  if (expiryVal >= 2 && expiryVal <= 9 && clearValue.length === 1) {
    formatted = "0" + clearValue + " / ";
  } else if (clearValue.length === 2 && expiryVal > 12) {
    var val$1 = clearValue.split("");
    formatted = "0" + getStrFromIndex(val$1, 0) + " / " + getStrFromIndex(val$1, 1);
  } else {
    formatted = clearValue;
  }
  if (clearValue.length >= 3) {
    return formatted.slice(0, 2) + " / " + formatted.slice(2, 4);
  } else {
    return formatted;
  }
}

function getCardBrand(cardNumber) {
  try {
    var card = cardNumber.replace(/[^\d]/g, "");
    var rupayRanges = [
      [
        508227,
        508227
      ],
      [
        508500,
        508999
      ],
      [
        603741,
        603741
      ],
      [
        606985,
        607384
      ],
      [
        607385,
        607484
      ],
      [
        607485,
        607984
      ],
      [
        608001,
        608100
      ],
      [
        608101,
        608200
      ],
      [
        608201,
        608300
      ],
      [
        608301,
        608350
      ],
      [
        608351,
        608500
      ],
      [
        652150,
        652849
      ],
      [
        652850,
        653049
      ],
      [
        653050,
        653149
      ],
      [
        817290,
        817290
      ]
    ];
    var masterCardRanges = [
      [
        222100,
        272099
      ],
      [
        510000,
        559999
      ]
    ];
    var doesFallInRange = function (cardRanges, isin) {
      var intIsin = Belt_Option.getWithDefault(Belt_Int.fromString(isin.replace(/[^\d]/g, "").substring(0, 6)), 0);
      var range = cardRanges.map(function (param, i) {
            var min = Caml_array.get(Caml_array.get(cardRanges, i), 0);
            var max = Caml_array.get(Caml_array.get(cardRanges, i), 1);
            if (intIsin >= min) {
              return intIsin <= max;
            } else {
              return false;
            }
          });
      return range.includes(true);
    };
    if (doesFallInRange(rupayRanges, card)) {
      return "RuPay";
    } else if (doesFallInRange(masterCardRanges, card)) {
      return "Mastercard";
    } else {
      return Belt_Option.getWithDefault(Belt_Array.get(CardPattern$OrcaPaymentPage.cardPatterns.map(function (item) {
                            if (Belt_Option.isSome(Caml_option.null_to_opt(card.match(item.pattern)))) {
                              return item.issuer;
                            } else {
                              return "";
                            }
                          }).filter(function (item) {
                          return item !== "";
                        }), 0), "");
    }
  }
  catch (_error){
    return "";
  }
}

function calculateLuhn(value) {
  var card = clearSpaces(value);
  var splitArr = card.split("").reverse();
  var unCheckArr = splitArr.filter(function (param, i) {
        return i % 2 === 0;
      });
  var checkArr = splitArr.filter(function (param, i) {
          return (i + 1 | 0) % 2 === 0;
        }).map(function (item) {
        var val = Belt_Option.getWithDefault(Belt_Int.fromString(item), 0);
        var $$double = (val << 1);
        if ($$double <= 9) {
          return String($$double);
        }
        var str = String($$double);
        var arr = str.split("");
        return String(Belt_Option.getWithDefault(Belt_Int.fromString(Caml_array.get(arr, 0)), 0) + Belt_Option.getWithDefault(Belt_Int.fromString(Caml_array.get(arr, 1)), 0) | 0);
      });
  var sumofCheckArr = Belt_Array.reduce(checkArr, 0, (function (acc, val) {
          return acc + Belt_Option.getWithDefault(Belt_Int.fromString(val), 0) | 0;
        }));
  var sumofUnCheckedArr = Belt_Array.reduce(unCheckArr, 0, (function (acc, val) {
          return acc + Belt_Option.getWithDefault(Belt_Int.fromString(val), 0) | 0;
        }));
  var totalSum = sumofCheckArr + sumofUnCheckedArr | 0;
  return totalSum % 10 === 0;
}

function getCardBrandIcon(cardType, paymentType) {
  switch (cardType) {
    case /* VISA */0 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "visa-light",
                    size: 28
                  });
    case /* MASTERCARD */1 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "mastercard",
                    size: 28
                  });
    case /* AMEX */2 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "amex-light",
                    size: 28
                  });
    case /* MAESTRO */3 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "maestro",
                    size: 28
                  });
    case /* DINERSCLUB */4 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "diners",
                    size: 28
                  });
    case /* DISCOVER */5 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "discover",
                    size: 28
                  });
    case /* RUPAY */8 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "rupay-card",
                    size: 28
                  });
    case /* JCB */9 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "jcb-card",
                    size: 28
                  });
    case /* BAJAJ */6 :
    case /* SODEXO */7 :
    case /* CARTESBANCAIRES */10 :
    case /* UNIONPAY */11 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "card",
                    size: 28
                  });
    case /* INTERAC */12 :
        return React.createElement(Icon$OrcaPaymentPage.make, {
                    name: "interac",
                    size: 28
                  });
    case /* NOTFOUND */13 :
        if (paymentType !== 1) {
          return React.createElement(Icon$OrcaPaymentPage.make, {
                      name: "default-card",
                      size: 28
                    });
        } else {
          return React.createElement(Icon$OrcaPaymentPage.make, {
                      name: "base-card",
                      size: 28
                    });
        }
    
  }
}

function getExpiryValidity(cardExpiry) {
  var date = new Date().toISOString();
  var match = getExpiryDates(cardExpiry);
  var year = match[1];
  var month = match[0];
  var match$1 = getCurrentMonthAndYear(date);
  var currentYear = match$1[1];
  if (currentYear === Belt_Option.getWithDefault(Belt_Int.fromString(year), 0) && Belt_Option.getWithDefault(Belt_Int.fromString(month), 0) >= match$1[0] && Belt_Option.getWithDefault(Belt_Int.fromString(month), 0) <= 12) {
    return true;
  } else if (Belt_Option.getWithDefault(Belt_Int.fromString(year), 0) > currentYear && Belt_Option.getWithDefault(Belt_Int.fromString(year), 0) < 2075 && Belt_Option.getWithDefault(Belt_Int.fromString(month), 0) >= 1) {
    return Belt_Option.getWithDefault(Belt_Int.fromString(month), 0) <= 12;
  } else {
    return false;
  }
}

function isExipryValid(val) {
  if (val.length > 0 && getExpiryValidity(val)) {
    return isExipryComplete(val);
  } else {
    return false;
  }
}

function cardNumberInRange(val) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(getCardBrand(val));
  return obj.length.map(function (item) {
              return clearValue.length === item;
            });
}

function max(a, b) {
  if (Caml_obj.caml_greaterthan(a, b)) {
    return a;
  } else {
    return b;
  }
}

function getMaxLength(val) {
  var obj = getobjFromCardPattern(getCardBrand(val));
  var maxValue = obj.length.reduce(max, 0);
  if (maxValue <= 12) {
    return maxValue + 2 | 0;
  } else if (maxValue <= 16) {
    return maxValue + 3 | 0;
  } else if (maxValue <= 19) {
    return maxValue + 4 | 0;
  } else {
    return maxValue + 2 | 0;
  }
}

function cvcNumberInRange(val, cardBrand) {
  var clearValue = clearSpaces(val);
  var obj = getobjFromCardPattern(cardBrand);
  return obj.cvcLength.map(function (item) {
              return clearValue.length === item;
            });
}

function genreateFontsLink(fonts) {
  if (fonts.length > 0) {
    fonts.map(function (item) {
          if (item.cssSrc !== "") {
            var link = document.createElement("link");
            link.href = item.cssSrc;
            link.rel = "stylesheet";
            return document.body.appendChild(link);
          }
          if (!(item.family !== "" && item.src !== "")) {
            return ;
          }
          var newStyle = document.createElement("style");
          newStyle.appendChild(document.createTextNode("@font-face {    font-family: \"" + item.family + "\";    src: url(" + item.src + ");    font-weight: \"" + item.weight + "\";}"));
          return document.body.appendChild(newStyle);
        });
    return ;
  }
  
}

function maxCardLength(cardBrand) {
  var obj = getobjFromCardPattern(cardBrand);
  return Belt_Array.reduce(obj.length, 0, (function (acc, val) {
                if (acc > val) {
                  return acc;
                } else {
                  return val;
                }
              }));
}

function cardValid(cardNumber, cardBrand) {
  var clearValue = clearSpaces(cardNumber);
  if (clearValue.length === maxCardLength(cardBrand)) {
    return calculateLuhn(cardNumber);
  } else {
    return false;
  }
}

function blurRef(ref) {
  Belt_Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (input) {
          input.blur();
          
        }));
  
}

function handleInputFocus(currentRef, destinationRef) {
  var optionalRef = destinationRef.current;
  if (optionalRef == null) {
    return blurRef(currentRef);
  } else {
    Belt_Option.forEach((optionalRef == null) ? undefined : Caml_option.some(optionalRef), (function (input) {
            input.focus();
            
          }));
    return ;
  }
}

function getCardElementValue(iframeId, key) {
  var firstIframeVal;
  if (parent.frames[0].name !== iframeId) {
    var dom = parent.frames[0].document.getElementById(key);
    firstIframeVal = (dom == null) ? "" : dom.value;
  } else {
    firstIframeVal = "";
  }
  var secondIframeVal;
  if (parent.frames[1].name !== iframeId) {
    var dom$1 = parent.frames[1].document.getElementById(key);
    secondIframeVal = (dom$1 == null) ? "" : dom$1.value;
  } else {
    secondIframeVal = "";
  }
  var thirdIframeVal;
  if (parent.frames[2].name !== iframeId) {
    var dom$2 = parent.frames[2].document.getElementById(key);
    thirdIframeVal = (dom$2 == null) ? "" : dom$2.value;
  } else {
    thirdIframeVal = "";
  }
  if (thirdIframeVal === "") {
    if (secondIframeVal === "") {
      return firstIframeVal;
    } else {
      return secondIframeVal;
    }
  } else {
    return thirdIframeVal;
  }
}

function checkCardCVC(cvcNumber, cardBrand) {
  if (cvcNumber.length > 0) {
    return cvcNumberInRange(cvcNumber, cardBrand).includes(true);
  } else {
    return false;
  }
}

function checkCardExpiry(expiry) {
  if (expiry.length > 0) {
    return getExpiryValidity(expiry);
  } else {
    return false;
  }
}

function getBoolOptionVal(boolOptionVal) {
  if (boolOptionVal !== undefined) {
    if (boolOptionVal) {
      return "valid";
    } else {
      return "invalid";
    }
  } else {
    return "";
  }
}

function commonKeyDownEvent(ev, srcRef, destRef, srcEle, destEle, setEle) {
  var key = ev.keyCode;
  if (key === 8 && srcEle === "") {
    handleInputFocus(srcRef, destRef);
    Curry._1(setEle, (function (param) {
            return destEle.slice(0, -1);
          }));
    ev.preventDefault();
    return ;
  }
  
}

function pincodeVisibility(cardNumber) {
  var brand = getCardBrand(cardNumber);
  return Belt_Option.getWithDefault(Belt_Array.get(CardPattern$OrcaPaymentPage.cardPatterns.filter(function (obj) {
                      return obj.issuer === brand;
                    }), 0), CardPattern$OrcaPaymentPage.defaultCardPattern).pincodeRequired;
}

function swapCardOption(cardOpts, dropOpts, selectedOption) {
  var popEle = cardOpts.pop();
  dropOpts.push(Belt_Option.getWithDefault(popEle === undefined ? undefined : Caml_option.some(popEle), ""));
  cardOpts.push(selectedOption);
  var temp = dropOpts.filter(function (item) {
        return item !== selectedOption;
      });
  return [
          cardOpts,
          temp
        ];
}

function setCardValid(cardnumber, setIsCardValid) {
  var cardBrand = getCardBrand(cardnumber);
  if (cardValid(cardnumber, cardBrand)) {
    return Curry._1(setIsCardValid, (function (param) {
                  return true;
                }));
  } else if (!cardValid(cardnumber, cardBrand) && cardnumber.length === maxCardLength(cardBrand)) {
    return Curry._1(setIsCardValid, (function (param) {
                  return false;
                }));
  } else if (cardnumber.length !== maxCardLength(cardBrand)) {
    return Curry._1(setIsCardValid, (function (param) {
                  
                }));
  } else {
    return ;
  }
}

function setExpiryValid(expiry, setIsExpiryValid) {
  if (isExipryValid(expiry)) {
    return Curry._1(setIsExpiryValid, (function (param) {
                  return true;
                }));
  } else if (!getExpiryValidity(expiry) && isExipryComplete(expiry)) {
    return Curry._1(setIsExpiryValid, (function (param) {
                  return false;
                }));
  } else if (!isExipryComplete(expiry)) {
    return Curry._1(setIsExpiryValid, (function (param) {
                  
                }));
  } else {
    return ;
  }
}

function getLayoutClass(layout) {
  if (layout.TAG === /* StringLayout */0) {
    return {
            defaultCollapsed: PaymentType$OrcaPaymentPage.defaultLayout.defaultCollapsed,
            radios: PaymentType$OrcaPaymentPage.defaultLayout.radios,
            spacedAccordionItems: PaymentType$OrcaPaymentPage.defaultLayout.spacedAccordionItems,
            type: layout._0
          };
  } else {
    return layout._0;
  }
}

function getAllBanknames(obj) {
  return obj.reduce((function (acc, item) {
                item.map(function (val) {
                      return acc.push(val);
                    });
                return acc;
              }), []);
}

var clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

var clientCountry = Utils$OrcaPaymentPage.getClientCountry(clientTimeZone);

function postalRegex(postalCodes, country, param) {
  var country$1 = country !== undefined ? country : clientCountry.isoAlpha2;
  var countryPostal = Utils$OrcaPaymentPage.getCountryPostal(country$1, postalCodes);
  if (countryPostal.regex === "") {
    return "";
  } else {
    return countryPostal.regex;
  }
}

export {
  toInt ,
  toString ,
  getQueryParamsDictforKey ,
  cardType ,
  getobjFromCardPattern ,
  clearSpaces ,
  slice ,
  getStrFromIndex ,
  formatCVCNumber ,
  getCurrentMonthAndYear ,
  formatCardNumber ,
  splitExpiryDates ,
  getExpiryDates ,
  formatExpiryToTwoDigit ,
  isExipryComplete ,
  formatCardExpiryNumber ,
  getCardBrand ,
  calculateLuhn ,
  getCardBrandIcon ,
  getExpiryValidity ,
  isExipryValid ,
  cardNumberInRange ,
  max ,
  getMaxLength ,
  cvcNumberInRange ,
  genreateFontsLink ,
  maxCardLength ,
  cardValid ,
  blurRef ,
  handleInputFocus ,
  getCardElementValue ,
  checkCardCVC ,
  checkCardExpiry ,
  getBoolOptionVal ,
  commonKeyDownEvent ,
  pincodeVisibility ,
  swapCardOption ,
  setCardValid ,
  setExpiryValid ,
  getLayoutClass ,
  getAllBanknames ,
  clientTimeZone ,
  clientCountry ,
  postalRegex ,
  
}
/* clientTimeZone Not a pure module */
